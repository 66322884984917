import styled from "styled-components";
import { FontHdrLargeStronger, FontBody } from "../../utils/font-styles";

export const Container = styled.div`
  max-width: 1000px;
  margin-right: 30px;
`;

export const TopHeader = styled.div`
  padding: 26px 26px 10px 26px;
`;

export const LargeTitle = styled(FontHdrLargeStronger)`
  text-align: left;
`;

export const WelcomeLabel = styled(FontBody)`
  margin-top: 12px;
`;
