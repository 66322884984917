import React from "react";
import { Wrapper, ItemTable } from "./_modalConsentStyle";

export const otherMarketingMaterial = () => {
  return (
    <Wrapper>
      <ItemTable>
        <table style={{ width: "100%" }}>
          <tr>
            <th style={{ width: "10%" }}>NO</th>
            <th style={{ width: "30%" }}>DISCLAIMERS / TERMS & CONDITIONS</th>
            <th style={{ width: "60%" }}>
              WORDINGS (NOT APPLICABLE FOR BROCHURES
            </th>
          </tr>
          <tr>
            <td>1</td>
            <td>When you market AIA products e.g. seminar, workshop: --</td>
            <tr>
              This [type of document] is for your information only and does not
              have regard to the specific investment objectives, financial
              situation and particular needs of any persons. It is intended only
              to be a simplified description of the product features applicable
              to this plan and is not exhaustive. The information presented is
              strictly confidential and for internal use only and cannot be
              reproduced, amended or circulated in whole or in part to anyone,
              including policyholders, and potential prospects, for whatever
              purpose or reason. Please refer to the policy contract for the
              precise terms and conditions of the product. Protected up to
              specified limits by SDIC. This [type of document] has not been
              reviewed by the Monetary Authority of Singapore. The information
              in this [collateral] is correct as at [date i.e. Day Month Year].
            </tr>
          </tr>
          <tr>
            <td>2</td>
            <td>
              When you market Investment- Linked Plans: -- (Please note that if
              you are highlighting projected returns to customers, you are
              required to disclose on the two illustrated rates
            </td>
            <td>
              [Plan Name] is an investment-linked plan (ILP) offered by AIA
              Singapore Private Limited (Reg. No. 201106386R) (“AIA”), which
              invests in ILP sub- fund(s). Investments in this plan are subject
              to investment risks including the possible loss of the principal
              amount invested. The value of the units in the ILP sub-fund(s) and
              the income accruing to the units, if any, may fall or rise. You
              should seek advice from an AIA FA Financial Consultant and read
              the product summary and product highlights sheet(s) before
              deciding whether the product is suitable for you. All insurance
              applications are subject to AIA’s underwriting and acceptance.
              This is not a contract of insurance. The precise terms and
              conditions of this plan, including exclusions whereby the benefits
              under your policy may not be paid out, are specified in the policy
              contract. You are advised to read the policy contract. As buying a
              life insurance policy is a long-term commitment, an early
              termination of the policy usually involves high costs and the
              surrender value, if any, that is payable to you may be zero or
              less than the total premiums paid. You should consider carefully
              before terminating the policy or switching to a new one as there
              may be disadvantages in doing so. The new policy may cost more or
              have fewer benefits at the same cost. Protected up to specified
              limits by SDIC. This [type of document] has not been reviewed by
              the Monetary Authority of Singapore. The information in this
              [collateral] is correct as at [date i.e. Day Month Year].
            </td>
          </tr>
          <tr>
            <td>3</td>
            <td>
              When you market Life Plans: -- (Please note that if you are
              highlighting projected returns to customers, you are required to
              disclose on the two illustrated rates)
            </td>
            <td>
              This insurance plan is underwritten by AIA Singapore Private
              Limited (Reg. No. 201106386R) (“AIA”). All insurance applications
              are subject to AIA's underwriting and acceptance. This [type of
              document] is not a contract of insurance. The precise terms and
              conditions of this plan, including exclusions whereby the benefits
              under your policy may not be paid out, are specified in the policy
              contract. You are advised to read the policy contract. As buying a
              life insurance policy is a long-term commitment, an early
              termination of the policy usually involves high costs and the
              surrender value, if any, that is payable to you may be zero or
              less than the total premiums paid. Protected up to specified
              limits by SDIC. This [type of document] has not been reviewed by
              the Monetary Authority of Singapore. The information in this
              [collateral] is correct as at [date i.e. Day Month Year]
            </td>
          </tr>
          <tr>
            <td>4</td>
            <td>
              When you market Life Plans with Health Insurance Benefits e.g. AIA
              Complete Critical Cover:
            </td>
            <td>
              This insurance plan is underwritten by AIA Singapore Private
              Limited (Reg. No. 201106386R) (“AIA”). All insurance applications
              are subject to AIA's underwriting and acceptance.This [type of
              document] is not a contract of insurance. The precise terms and
              conditions of this plan, including exclusions whereby the benefits
              under your policy may not be paid out, are specified in the policy
              contract. You are advised to read the policy contract.As buying a
              life insurance policy is a long-term commitment, an early
              termination of the policy, if any, usually involves high costs and
              the surrender value that is payable to you may be zero or less
              than the total premiums paid.Buying health insurance products that
              are not suitable for you may impact your ability to finance your
              future healthcare needs.Protected up to specified limits by SDIC.
              This [type of document] has not been reviewed by the Monetary
              Authority of Singapore. The information in this [collateral] is
              correct as at [date i.e. Day Month Year].
            </td>
          </tr>
          <tr>
            <td>5</td>
            <td>When you market A&H Plans:</td>
            <td>
              This insurance plan is underwritten by AIA Singapore Private
              Limited (Reg. No. 201106386R) (“AIA”). All insurance applications
              are subject to AIA's underwriting and acceptance. This [type of
              document] is not a contract of insurance. The precise terms and
              conditions of this plan, including exclusions whereby the benefits
              under your policy may not be paid out, are specified in the policy
              contract. You are advised to read the policy contract. Buying
              health insurance products that are not suitable for you may impact
              your ability to finance your future healthcare needs. FOR A&H PLAN
              THAT PAYS BENEFITS UPON ACCIDENT ONLY - ALL COLLATERALS: [For PA
              plans which have majority of its benefits that payout due to
              accident, but a few minority A&H benefits not requiring accident
              to occur, e.g. dengue fever, HFMD] Most of the benefits of this
              policy / supplementary benefit will be payable upon the occurrence
              of an accident [For pure PA plans] The benefits of this policy /
              supplementary benefit will only be payable upon an accident
              occurring. Protected up to specified limits by SDIC. The
              information in this [collateral] is correct as at [date i.e. Day
              Month Year].
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              When you market products with non-guaranteed benefits (e.g.
              bonuses): -- (Please note that if you are highlighting projected
              returns to customers, you are required to disclose on the two
              illustrated rates)
            </td>
            <td>
              As the bonus rates used for the benefits illustrated are not
              guaranteed, the actual benefits payable may vary according to the
              future experience of the fund.
            </td>
          </tr>
          <tr>
            <td>7</td>
            <td>
              When you make competitor analysis on products: -- (Please note
              that if you are highlighting projected returns to customers, you
              are required to disclose on the two illustrated rates)
            </td>
            <td>
              Important Notes: This comparison does not include information on
              all similar products. AIA FA does not guarantee that all aspects
              of the products have been illustrated. You may wish to conduct
              your own comparison for products that are listed in
              www.comparefirst.sg.The information presented is strictly
              confidential and for internal use only and cannot be reproduced,
              amended or circulated in whole or in part to anyone, including
              policyholders and potential prospects, for whatever purpose or
              reason. Information is correct as at DDMMYYYY.
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>When you offer sign-up gifts for roadshows:</td>
            <td>
              Please refer to the “Standard T&C for Roadshows (sign-up gift
              offer)” posted in Agent Internet Access for the standard
              pre-approved Terms & Conditions that you should use for your
              materials, if you are offering sign- up gifts to customers for
              purchase of AIA products. The Terms & Conditions may be updated
              from time and time.
            </td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              With rebates/ free gifts: - Guideline for those run by agents is
              at max (10% of ANP or ≤ $1200) and must be approved by the company
              prior to implementation - Only mention/ highlight to the customer
              after a policy is recommended from a financial planning session
              and the sale is closed
            </td>
            <td>
              Please seek professional financial advice from your AIA FA
              Financial Consultant before making a purchase.
            </td>
          </tr>
        </table>
      </ItemTable>
    </Wrapper>
  );
};
