import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { FontBodyStronger, FontHdrMedium } from "../../utils/font-styles";
import {
  Container,
  StatsCard,
  StatsContent,
  StatsItem,
  StatsLabel,
} from "./_QuickStatsStyle";

const QuickStats = ({
  totalMedia,
  pendingApproval,
  mediaShare,
  approvedMedia,
}) => {
  return (
    <Container>
      <FontBodyStronger>Quick Starts</FontBodyStronger>
      <StatsContent>
        <Row>
          <Col xs={24} xl={6}>
            <StatsCard>
              <StatsItem>
                <StatsLabel>Total Media</StatsLabel>
                <FontHdrMedium>{totalMedia}</FontHdrMedium>
              </StatsItem>
            </StatsCard>
          </Col>
          <Col xs={24} xl={6}>
            <StatsCard>
              <StatsItem>
                <StatsLabel>Approved Media</StatsLabel>
                <FontHdrMedium>{approvedMedia}</FontHdrMedium>
              </StatsItem>
            </StatsCard>
          </Col>
          <Col xs={24} xl={6}>
            <StatsCard>
              <StatsItem>
                <StatsLabel>Pending Approval</StatsLabel>
                <FontHdrMedium>{pendingApproval}</FontHdrMedium>
              </StatsItem>
            </StatsCard>
          </Col>
          <Col xs={24} xl={6}>
            <StatsCard>
              <StatsItem>
                <StatsLabel>Social Media Share</StatsLabel>
                <FontHdrMedium>{mediaShare}</FontHdrMedium>
              </StatsItem>
            </StatsCard>
          </Col>
        </Row>
      </StatsContent>
    </Container>
  );
};

QuickStats.propTypes = {
  totalMedia: PropTypes.number,
  pendingApproval: PropTypes.number,
  mediaShare: PropTypes.number,
  approvedMedia: PropTypes.number,
};

QuickStats.defaultProps = {
  totalMedia: 0,
  pendingApproval: 0,
  mediaShare: 0,
  approvedMedia: 0,
};

export default QuickStats;
