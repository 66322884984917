import React from "react";
import { Link } from "gatsby";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { theme } from "../../../utils/constants";
import "../../../utils/fontawesome";

const MenuItemStyled = styled(Menu.Item)`
  && {
    &.ant-menu-item-selected {
      background-color: white;
      border-right: 3px solid ${({ theme }) => theme.color.p2};
      color: ${({ theme }) => theme.color.p1};
    }
    &.ant-menu-item-selected::after {
      border-right: 0px;
    }
  }
`;

export const MenuItem = ({ key, title, icon, to, ...props }) => {
  return (
    <MenuItemStyled
      key={key}
      icon={<FontAwesomeIcon icon={icon} size="6x" color={theme.color.p1} />}
      {...props}
    >
      <Link to={to}>{title}</Link>
    </MenuItemStyled>
  );
};
