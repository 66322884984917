import React from "react";
import { Container, LinkStyled } from "./_forgotLinkStyle";
function ForgotLink(props) {
  return (
    <Container>
      <LinkStyled href="/app/forgot-passoword">Forgot Password</LinkStyled>
    </Container>
  );
}

export default ForgotLink;
