import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPendingInstances } from "../../redux/agent/actions";
import ContentInstance from "../ContentInstance";
import { Col, Row } from "../Grid";
import Layout from "../Layout";
import { PageHeader } from "../UI";

function ApprovedPage() {
  const dispatch = useDispatch();
  const { pendingInstances, user } = useSelector((state) => {
    return {
      pendingInstances: state.agent.pendingInstances,
      user: state.auth.user
    };
  }, shallowEqual);

  useEffect(() => {
    loadPendingInstance();
  }, []);

  const loadPendingInstance = () => {
    dispatch(fetchPendingInstances());
  };

  return (
    <Layout>
      <PageHeader title="Pending Approval" user={user} />
      <Row type="flex" justify="center">
        <Col xs={17} lg={17} xl={17} sm={17}>
          <ContentInstance data={pendingInstances} />
        </Col>
      </Row>
    </Layout>
  );
}

export default ApprovedPage;
