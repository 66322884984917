import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  CheckMediaType,
  DownloadProtectedMedia,
  GetToken,
} from "../../../utils/helper";
import DocPlaceholder from "../../../assets/images/doc_placeholder.png";
import { Container, ImagePreview } from "./_ContentPreviewStyle";

const ContentPreview = ({
  url,
  type,
  widthPreview,
  heightPreview,
  widthVideo,
  objectFitImage,
}) => {
  const [blobUrl, setBlobUrl] = useState(null);

  useEffect(() => {
    if (url) {
      getBlobImage(url);
    }
  }, [url]);

  const getBlobImage = async (path) => {
    const token = GetToken();
    const response = await DownloadProtectedMedia(token, path);
    if (response) {
      setBlobUrl(response);
    }
  };

  const renderPreview = (url) => {
    const mediaType = CheckMediaType(type);
    switch (mediaType) {
      case "Image":
        return (
          <ImagePreview>
            <img
              src={url}
              alt={url}
              style={{
                width: "100%",
                maxWidth: widthPreview,
                height: heightPreview,
                objectFit: objectFitImage,
              }}
            />
          </ImagePreview>
        );
      case "Video":
        return (
          <video width={widthVideo} height={heightPreview} controls>
            <source src={url} type={"video/mp4"} />
            Your browser does not support HTML5 video.
          </video>
        );
      case "Doc":
        return (
          <img
            src={DocPlaceholder}
            style={{
              width: "100%",
              maxWidth: widthPreview,
              height: heightPreview,
            }}
          />
        );
      default:
        return null;
    }
  };

  return <Container>{blobUrl && renderPreview(blobUrl)}</Container>;
};

ContentPreview.propTypes = {
  url: PropTypes.string,
  widthPreview: PropTypes.number,
  heightPreview: PropTypes.number,
  widthVideo: PropTypes.number,
  objectFitImage: PropTypes.string,
};

ContentPreview.defaultProps = {
  url: "",
  widthPreview: null,
  heightPreview: 100,
  widthVideo: 100,
  objectFitImage: "contain",
};

export default ContentPreview;
