import React from "react";
import PropTypes from "prop-types";
import Overlay from "../Overlay";
import SocialIcons from "../SocialIcons";

function ShareModal(props) {
  const { visible, onCancel } = props;

  const handleClick = (i) => {};

  return (
    <Overlay visible={visible} footer={null} onCancel={onCancel}>
      <SocialIcons
        onClick={(i) => handleClick(i)}
        containerStyle={{ marginTop: 40 }}
      />
    </Overlay>
  );
}

ShareModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.bool,
};

ShareModal.defaultProps = {
  visible: false,
  onCancel: () => {},
};

export default ShareModal;
