import styled from "styled-components";

export const FontHdrLargeStronger = styled.div`
  font-family: Raleway;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.6;
  letter-spacing: normal;
  text-align: left;
  color: #11141a;
`;

export const FontHdrLarge = styled.div`
  font-family: HalyardDisplay;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

export const FontHdrMedium = styled.div`
  font-family: Arial;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #11141a;
`;

export const FontHdrThin = styled.div`
  font-family: Arial;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: #11141a;
`;

export const FontHdrSmall = styled.div`
  font-family: Arial;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

export const FontBodyStronger = styled.span`
  font-family: Raleway;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #11141a;
`;

export const FontBodyMedium = styled.div`
font-family: Arial;
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #354052;
}
`;

export const FontBodyStrong = styled.div`
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #354052;
`;

export const FontBody = styled.div`
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #7f8fa4;
`;

export const InfoFontBody = styled.div`
  font-family: Arial;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: red;
  margin-bottom:10px;
`;

export const FontCaption = styled.span`
  font-family: Arial;
  font-size: 7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #354052;
`;
