import { Col, Row } from "antd";
import React, { useState } from "react";
import { InstanceCard, SubmitApprovalModal } from "../UI";
import { Container } from "./_ContentInstanceListStyle";
const ContentInstanceList = ({ type, data, page }) => {
  const [showModal, setIsShowModal] = useState(false);
  const [instance, setInstance] = useState(null);

  const handleClickItem = (item) => {
    setIsShowModal(true);
    setInstance(item);
  };

  return (
    <Container>
      <Row gutter={[16, 16]}>
        {data.map((_instance, i) => (
          <Col xs={24} sm={12} xl={6} key={i}>
            <InstanceCard
              item={_instance}
              onClick={() => handleClickItem(_instance)}
            />
          </Col>
        ))}
      </Row>
      <SubmitApprovalModal
        type={type}
        visible={showModal}
        item={instance}
        onSuccessSubmit={() => {
          setIsShowModal(false);
        }}
        onCancel={() => {
          setIsShowModal(false);
        }}
      />
    </Container>
  );
};

export default ContentInstanceList;
