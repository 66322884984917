import styled from "styled-components";

export const Container = styled.div`
  width: 380px;
`;

export const TopView = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 26px 26px 0;
`;
