import { Link } from "gatsby";
import styled from "styled-components";
import { FontHdrLargeStronger, FontHdrLarge } from "../../utils/font-styles";

export const LabelTitle = styled(FontHdrLarge)`
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  padding-bottom: 40px;
`;

export const ContentMedia = styled.div`
  background-color: white;
`;

export const ContentView = styled.div`
  margin: 0 auto;
  margin-top: 10px;
  max-width: 484px;
  height: 291px;
`;

export const BottomView = styled.div`
  text-align: center;
  padding-bottom: 160px;
  background-color: white;
`;

export const LabelOr = styled(FontHdrLargeStronger)`
  font-size: 26px;
  text-align: center;
  padding: 26px;
`;

export const LinkStyled = styled(Link)`
  margin: 0 auto;
  max-width: 484px;
  text-transform: uppercase;
  color: white;
  padding: 10px 18px;
  border-radius: 8px;
  background-color: #d31145;
  &:hover {
    color: white;
  }
  cursor: pointer;
`;
