import { Progress } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import Overlay from "../Overlay";
import shareIcon from "./assets/share.svg";
import {
  Container,
  Image, ImageWrapper, PercentText, Rectangle, WaitText
} from "./_modalProgressStyle";
function ModalProgress(props) {
  const { visible } = props;
  const { progressTotal } = useSelector(
    (state) => {
      return {
        progressTotal : state.agent.progressTotal
      };
    },
    shallowEqual
  );
  return (
    <Overlay
      width={351.5}
      visible={visible}
      footer={null}
      closable={false}
      keyboard={false}
      preserve={false}
      destroyOnClose={true}
      closeIcon={null}
      maskClosable={false}
    >
      <Container>
        <ImageWrapper>
          <Image src={shareIcon} />
        </ImageWrapper>
        <WaitText>Have a break/ enjoy a cuppa whilst we work on your submission…</WaitText>
        <Rectangle>
          <PercentText>{progressTotal}%</PercentText>
          <Progress percent={progressTotal} status="exception" showInfo={false} />
        </Rectangle>
      </Container>
    </Overlay>
  );
}

ModalProgress.propTypes = {
  visible: PropTypes.bool,
};

ModalProgress.defaultProps = {
  visible: false,
};

export default ModalProgress;
