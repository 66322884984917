import styled from "styled-components";
import {
  FontBodyStrong,
  FontBody,
  FontCaption,
} from "../../../utils/font-styles";
import { Badge } from "antd";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const WrapperRevise = styled.div`
  margin-top: 4px;
`;

export const WrapperScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ScoreLabel = styled(FontBodyStrong)``;

export const BadgeStyled = styled(Badge)`
  margin-left: 6px;
  width: 40px;
`;

export const ReviseLabel = styled(FontBody)`
  margin-top: 8px;
  font-size: 10px;
`;

export const DateLabel = styled(FontCaption)``;
