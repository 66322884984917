import styled from "styled-components";
import { FontBody } from "../../../utils/font-styles";

export const Container = styled.div``;

export const MediaContainer = styled.div`
  margin: 0 auto;
  margin-bottom: 24px;
  width: 250px;
  height: 250px;
  background-color: "#000";
`;

export const DetailLabel = styled(FontBody)`
  margin-top: 8px;
`;
