import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchRejectedInstances } from "../../redux/agent/actions";
import ContentInstance from "../ContentInstance";
import { Col, Row } from "../Grid";
import Layout from "../Layout";
import { PageHeader } from "../UI";

function PendingPage() {
  const dispatch = useDispatch();
  const { rejectInstances, user } = useSelector((state) => {
    return {
      rejectInstances: state.agent.rejectInstances,
      user: state.auth.user
    };
  }, shallowEqual);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch(fetchRejectedInstances());
  };

  return (
    <Layout>
      <PageHeader title="Rejected" user={user} />
      <Row type="flex" justify="center">
        <Col xs={17} lg={17} xl={17} sm={17}>
          <ContentInstance data={rejectInstances} />
        </Col>
      </Row>
    </Layout>
  );
}

export default PendingPage;
