import React from "react";
import PropTypes from "prop-types";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import { theme } from "../../../utils/constants";

function StatusIcon(props) {
  const { name, containerStyle } = props;
  let Icon = (
    <CheckOutlined style={{ color: theme.color.c2, fontSize: "16px" }} />
  );
  if (name.indexOf("error") > -1) {
    Icon = (
      <CloseOutlined style={{ color: theme.color.p1, fontSize: "16px" }} />
    );
  } else if (name.indexOf("warning") > -1) {
    Icon = (
      <ExclamationOutlined
        style={{ color: theme.color.c3, fontSize: "16px" }}
      />
    );
  }
  return <span style={{ ...containerStyle }}>{Icon}</span>;
}

StatusIcon.propTypes = {
  name: PropTypes.oneOf(["success", "error", "warning"]),
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

StatusIcon.defaultProps = {
  name: "success",
  containerStyle: {},
};

export default StatusIcon;
