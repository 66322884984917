import React from "react";
import PropTypes from "prop-types";
import { Container, Icon, Wrapper, Elipse } from "./_badgeNotificationStyle";
import notifications from "./assets/notifications.svg";
function BadgeNotification(props) {
  const { count } = props;

  return (
    <Container>
      <Wrapper>
        <Icon src={notifications} />
        {count > 0 && <Elipse />}
      </Wrapper>
    </Container>
  );
}

BadgeNotification.propTypes = {
  count: PropTypes.number,
};

BadgeNotification.defaultProps = {
  count: 0,
};

export default BadgeNotification;
