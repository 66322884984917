import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col } from "antd";
import { Row } from "../Grid";
import { Sidebar } from "../UI";
import BaseLayout from "./BaseLayout";
import Content from "./Content";
import Footer from "./Footer";
import "./../../utils/fontawesome";

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "flex-start"};
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.color.w};
  text-transform: none;
`;

export const IconButton = styled.img`
  cursor: pointer;
`;

const Layout = ({
  children,
  withHeader,
  withFooter,
  title,
  withBackButton,
  dispatch,
  isLoading,
  withLogout,
  withSidebar,
  ...props
}) => {
  const headerHeight = withHeader && "60px";
  const year = new Date().getFullYear();
  return (
    <BaseLayout {...props}>
      {withSidebar && <Sidebar />}
      <BaseLayout>
        <Content headerheight={headerHeight}>{children}</Content>
        {withFooter && (
          <Footer>
            <Row
              type="flex"
              justify="center"
              align="middle"
              padding="10px 20px"
            >
              <Col>
                <p style={{ textAlign: "center" }}>
                  Copyright &copy; {year} AIA Group Limited and its
                  subsidiaries. All rights reserved.
                </p>
              </Col>
            </Row>
          </Footer>
        )}
      </BaseLayout>
    </BaseLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  withHeader: PropTypes.bool,
  withFooter: PropTypes.bool,
  title: PropTypes.string,
  withBackButton: PropTypes.bool,
  withLogout: PropTypes.bool,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  withSidebar: PropTypes.bool,
};

Layout.defaultProps = {
  withHeader: false,
  withFooter: false,
  withBackButton: false,
  withLogout: true,
  title: null,
  dispatch: null,
  isLoading: false,
  withSidebar: true,
};

export default Layout;
