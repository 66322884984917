import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";
import styled from "styled-components";

const InputStyled = styled(Input)`
  display: block;
  width: 100% !important;
  background: white;
`;

function TextInput(props) {
  const { isPassword } = props;
  if (isPassword) {
    return <InputStyled.Password {...props} autoComplete="off" />;
  } else {
    return <InputStyled {...props} autoComplete="off" />;
  }
}

TextInput.propTypes = {
  isPassword: PropTypes.bool,
};

TextInput.defaultProps = {
  isPassword: false,
};

export default TextInput;
