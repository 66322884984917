import styled from "styled-components";
import { FontBody } from "../../../utils/font-styles";

export const Container = styled.div`
  width: 309.5px;
  margin: 0 auto;
  padding: 20px;
  border: solid 1px #c5d1e2;
  background-color: #f4f8fb;
  border-radius: 20px;
`;

export const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const Image = styled.img`
  height: 65px;
`;

export const WaitText = styled(FontBody)`
  font-weight: bold;
  text-align: center;
  color: #636c80;
`;

export const Rectangle = styled.div`
  width: 258.5px;
  margin: 10px 0 0;
  padding: 9.5px 12px 13px;
  border-radius: 12px;
  box-shadow: 0 26.5px 43px 0 rgba(0, 76, 116, 0.1);
  background-color: #ffffff;
`;
export const PercentText = styled(FontBody)`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #636c80;
`;
