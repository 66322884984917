import React from "react";
import styled from "styled-components";
import { Upload as UploadAntd } from "antd";
import { FontHdrThin, FontBody } from "../../../utils/font-styles";

const UploadStyledAntd = styled(UploadAntd)`
  .ant-upload.ant-upload-select-picture-card {
    border: dashed 1.5px #d31145;
  }
  .ant-upload.ant-upload-select-picture-card:hover {
    border: solid 1.5px #d31145 !important;
    background-color: transparent !important;
  }
`;

const InfoUplaodFont = styled(FontBody)`
  text-align: center;
`;

function UploadStyled(props) {
  return (
    <>
      <UploadStyledAntd {...props} />
      <>
        <InfoUplaodFont>
          Allowed Content Type (.pdf, .png, .jpg, .jpeg , .mpeg, and .mp4).
          <br />
          Maximum file size (512 MB).
        </InfoUplaodFont>
      </>
    </>
  );
}

export default UploadStyled;
