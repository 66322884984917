import {
  ExceptionOutlined,
  ExclamationCircleOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import {
  fetchComments,
  postComments,
  requestDeleteInstance,
  requestSubmitInstance,
  requestUploadContent,
} from "../../../redux/agent/actions";
import { INSTANCE_STATUS } from "../../../utils/constants";
import ContentPreview from "../ContentPreview";
import Loading from "../Loading";
import Score from "../Score";
import ShareModal from "../ShareModal";
import UploadForm from "../UploadForm";
import ModalConsent from "./../ModalConsent";
import ImageShieldJpg from "../../../assets/images/APPROVED.png";
import {
  CardWrapper,
  Container,
  ImageShield,
  MediaContainer,
  NameLabel,
} from "./_submitApprovalModalStyle";
import CommentContentModal from "../CommentContentModal";
import AiScoreDetailModel from "../AiScoreDetailModal";
import { ModalProgress } from "..";
import Comments from "./Comments";

import { purpose } from "./../../../utils/data.json";
const { confirm } = Modal;

function SubmitApprovalModal(props) {
  const { type, visible, onCancel, item, onSuccessSubmit } = props;
  const dispatch = useDispatch();
  const [contentSelected, setContentSelected] = useState(null);
  const [showModalInputComment, setShowModalInputComment] = useState(false);
  const {
    isLoading,
    isSubmitToReviewSuccess,
    isDeleteSuccess,
    comments,
    isUploading,
    isSuccessUpload,
    user,
  } = useSelector((state) => {
    return {
      isSubmitToReviewSuccess: state.agent.isSubmitToReviewSuccess,
      isLoading: state.agent.isLoading,
      isDeleteSuccess: state.agent.isDeleteSuccess,
      comments: state.agent.comments,
      isUploading: state.agent.isUploading,
      isSuccessUpload: state.agent.isSuccessUpload,
      user: state.auth.user,
    };
  }, shallowEqual);

  const [showShareModal, setShowShareModal] = useState(false);
  const [showAiDetail, setShowAiDetail] = useState(false);
  const [showConsent, setShowConsent] = useState(false);

  const [purposeType, setPurposeType] = useState(null);
  const [resubmitValues, setResubmitValues] = useState(null);

  useEffect(() => {
    if (item?.agencyMarketingMaterial) {
      const { id } = purpose.find((data) => data.name === item?.purpose);
      setPurposeType(id);
    }
  }, [item?.agencyMarketingMaterial]);

  useEffect(() => {
    if (isSubmitToReviewSuccess) {
      onSuccessSubmit();
    }
  }, [isSubmitToReviewSuccess]);

  useEffect(() => {
    if (isDeleteSuccess) {
      onSuccessSubmit();
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isSuccessUpload) {
      navigate("/app/pending-approval", { replace: true });
    }
  }, [isSuccessUpload]);

  useEffect(() => {
    if (item) {
      loadComments({ instanceID: item?.id });
    }
  }, [item]);

  const loadComments = (params) => {
    if (params) {
      dispatch(fetchComments(params));
    }
  };

  const showComment = (content) => {
    setShowModalInputComment(true);
    setContentSelected(content);
  };

  const showAiModal = (content) => {
    setShowAiDetail(true);
    setContentSelected(content);
  };

  const showConfirm = () => {
    confirm({
      title: "Do you Want to submit these item?",
      icon: <ExceptionOutlined />,
      content: "",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        submitInstanceToReview();
      },
      onCancel() {},
    });
  };

  const showConfirmDelete = () => {
    confirm({
      title: "Are you sure you want to delete these item?",
      icon: <ExceptionOutlined />,
      content: "",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        onDeleteInstance();
      },
      onCancel() {},
    });
  };

  const submitInstanceToReview = () => {
    dispatch(requestSubmitInstance(item?.id));
  };

  const onDeleteInstance = () => {
    dispatch(requestDeleteInstance(item?.id));
  };

  const onSubmitComment = (params) => {
    dispatch(postComments(params));
  };
  const handleOnAgentMaterial = () => {
    setShowConsent(true);
  };

  const showConfirmResubmit = (values) => {
    confirm({
      title: "Are you sure want to Re-Submit?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        resubmitToServer(values);
      },
      onCancel() {},
    });
  };

  const resubmitToServer = (values) => {
    const formData = new FormData();
    if (values?.files?.length > 0) {
      values?.files.forEach((file, i) => {
        formData.append(`files`, file.originFileObj, file.originFileObj.name);
      });
    }
    formData.append("instanceID", values?.instanceID);
    dispatch(requestUploadContent(formData, true));
  };

  const renderConsentChannel = () => {
    return (
      <ModalConsent
        channel={user?.channel}
        agencyMarketingMaterial={item?.agencyMarketingMaterial}
        visible={showConsent}
        type={purposeType}
        onSubmit={() => {
          setShowConsent(false);
        }}
        onCancel={() => {
          setShowConsent(false);
        }}
      />
    );
  };

  return (
    <>
      <Modal
        visible={visible}
        {...props}
        footer={null}
        onCancel={onCancel}
        width={700}
        bodyStyle={{ overflowY: "scroll" }}
      >
        <>
          <Container>
            <Row justify="start">
              {item?.Contents?.map((content, key) => (
                <Col span={6} style={{ paddingRight: 8 }}>
                  <CardWrapper isRevised={content?.isRevised}>
                    <MediaContainer>
                      <ContentPreview
                        type={content?.type}
                        url={content?.urlThumbnail}
                      />
                    </MediaContainer>
                    <NameLabel>{content?.name}</NameLabel>
                    <Button
                      type="default"
                      onClick={() => {
                        showAiModal(content);
                      }}
                    >
                      <Score
                        point={content?.ai_score}
                        status={null}
                        isRevised={content?.isRevised}
                        createdAt={content?.createdAt}
                      />
                    </Button>
                    {/* {item?.status !== 0 && (
                      <ButtonCommentWrapper>
                        <ButtonIcon
                          style={{ justifyContent: "center" }}
                          type="comment"
                          onClick={() => {
                            showComment(content);
                          }}
                        >
                          Comment
                        </ButtonIcon>
                      </ButtonCommentWrapper>
                    )} */}
                  </CardWrapper>
                </Col>
              ))}
            </Row>
            <UploadForm
              instanceStatus={item?.status}
              onSubmitToReview={() => {
                showConfirm();
              }}
              onDeleteInstance={() => {
                showConfirmDelete();
              }}
              isPreview={true}
              isSubmitToReview={true}
              initialValues={{
                title: item?.title,
                purpose: item?.purpose,
                platform: item?.platform,
                usage: item?.usage,
                agencyMarketingMaterial: item?.agencyMarketingMaterial,
                permitNumber: item?.permitNumber,
                id: item?.id,
              }}
              onAgentMaterial={() => handleOnAgentMaterial()}
              onResubmit={(values) => {
                setResubmitValues(values);
                showConfirmResubmit(values);
              }}
            />

            <Comments
              onSubmitComment={onSubmitComment}
              comments={comments}
              status={item?.status}
              onCancelComment={() => {
                onCancel();
              }}
              instanceID={item?.id}
              contents={item?.Contents || []}
              loadComments={loadComments}
            />

            {item?.status === INSTANCE_STATUS.Approved &&
              item?.channel === "social media" && (
                <Button
                  onClick={() => {
                    setShowShareModal(true);
                  }}
                  type="primary"
                  icon={<ShareAltOutlined />}
                  style={{ marginTop: 12 }}
                >
                  Share
                </Button>
              )}
          </Container>
        </>
        {isLoading && <Loading />}
        <ShareModal
          visible={showShareModal}
          onCancel={() => {
            setShowShareModal(false);
          }}
        />
        {item?.status === INSTANCE_STATUS.Approved && (
          <ImageShield src={ImageShieldJpg} />
        )}
      </Modal>
      <CommentContentModal
        content={contentSelected}
        visible={showModalInputComment}
        onDismiss={() => {
          setShowModalInputComment(false);
        }}
      />
      <AiScoreDetailModel
        content={contentSelected}
        visible={showAiDetail}
        onDismiss={() => {
          setShowAiDetail(false);
        }}
      />
      {renderConsentChannel()}
      <ModalProgress visible={isUploading} footer={null} />
    </>
  );
}

SubmitApprovalModal.propTypes = {
  visible: PropTypes.bool,
  item: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

SubmitApprovalModal.defaultProps = {
  visible: false,
  item: {},
  onCancel: () => {},
  onSubmit: () => {},
};

export default SubmitApprovalModal;
