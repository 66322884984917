import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import Button from "./../Button";
import ToggleButton from "./../ToggleButton";
import {
  Title,
  SubTitle,
  TopRow,
  Item,
  Footer,
  Wrapper,
  Bottom,
  Content,
} from "./_modalConsentStyle";
import { FontBody } from "../../../utils/font-styles";
import {
  renderSocialMediaAccountSetup,
  renderAllRecruitmentMaterial,
  renderContactSurveyRegForm,
  renderWebsiteCheklist,
  renderAdvertisingContentType,
} from "./CheklistForAFA";
import { otherMarketingMaterial } from "./AFAOtherMarketingMaterialContent";

function ModalConsent(props) {
  const {
    type,
    visible,
    onCancel,
    onSubmit,
    agencyMarketingMaterial,
    channel,
  } = props;

  const [fieldCheck, setFieldCheck] = useState("");

  useEffect(() => {
    if (agencyMarketingMaterial !== null) {
      setFieldCheck(agencyMarketingMaterial);
    }
  }, [agencyMarketingMaterial]);

  const renderWebinarEdm = () => {
    return (
      <Wrapper>
        <SubTitle>General Guideline on webinar invitation EDM:</SubTitle>
        <Item>
          <ol type="a">
            <li>
              To indicate the authorised representative liner: (Agency name) is
              an authorised representative of {(channel==="AGY"?"AIA Singapore Private Limited (Reg. No. 201106386R)":"AIA Financial Advisers Private Limited (Reg. No. 201715016G)")}
            </li>
            <li>
              Should you need to include your email address, please only use the
              AIA branded email (e.g. nicoletan@{(channel==="AGY"?"aia.com.sg":"aiafa.com.sg")}).
            </li>
            <li>Indicate only company approved agency logo and name</li>
            <li>
              All agents to be referred as “Financial {(channel==="AGY"?"Services":"")} Consultant”
            </li>
            <li>
              Always ensure that contents/information are factual and
              non-misleading. Ensure information created/shared on any
              products/campaign/ promotions is clear and consistent with
              corporate communications.
            </li>
            <li>
              The contents (eg images, logos, slogans etc) do not infringe any
              copyright, trademark, patent, trade secret, right of publicity or
              privacy, or other proprietary rights.
            </li>
            <li>
              Any fine prints to be in a font size of at least 10-point Times
              New Roman or any other standard font type that is visually
              equivalent to that font size
            </li>
            <li>
              Indicate any/all sources of information/references clearly and
              fully for any statistics, graphs, illustrations, quotes or
              newspaper articles. Always use the most updated reference for
              relevance.
            </li>
            <li>
              Indicate any applicable footnotes and the T&amp;Cs upfront and
              clearly.
            </li>
          </ol>
        </Item>
      </Wrapper>
    );
  };

  const renderContactSurvey = () => {
    return (
      <Wrapper>
        <SubTitle> Contact / registration form </SubTitle>
        <Item>
          To ensure FSCs use the correct form template with the proper data
          privacy disclosures indicated i.e. recruitment vs marketing. Any data
          collection to be hosted on MS Forms (refer to memo 20191231, Branded
          Email Migration Completion and New Password Requirements).
        </Item>
      </Wrapper>
    );
  };
  
  const renderSocialMediaAccount = () => {
    return (
      <Wrapper>
        <SubTitle>
          On social media accounts (eg) agency district Facebook/ district
          instagram/ district LinkedIn, please note:
        </SubTitle>
        <Item>
          <ol type="a">
            <li>
              Gentle reminder, FSCs creating new content to be shared on social
              media such as customer videos and flyers, that content must be
              approved by AIA before use.
            </li>
            <li>
              Representation on Agency District Facebook pages:
              <ol type="i">
                <li>
                  Indicate the authorised rep liner under the More Info About
                  section at the end of the Agency’s description paragraph:
                  SP-XXX is an authorised representative of AIA Singapore
                  Private Limited (Reg. No. 201106386R). This is not an official
                  AIA Singapore Facebook page.
                </li>
                <li>
                  Under the Services section, indicate as providing “Financial
                  Services” instead of an “Insurance Company”.
                </li>
              </ol>
            </li>
            <li>
              Representation on Agency District Instagram pages: Indicate the
              authorised rep liner: SP-XXX is an authorised representative of
              @AIASingapore
            </li>
            <li>
              Representation on Agency District/ FSC LinkedIn pages: Indicate
              the authorised rep liner under the About Us section: SP-XXX is an
              authorised representative of AIA Singapore Private Limited (Reg.
              No. 201106386R)
            </li>
          </ol>
        </Item>
      </Wrapper>
    );
  };

  const agyRecruitmentMaterial = () => {
    return (
      <Wrapper>
        <SubTitle>Guidelines for Advertisements (AIA-Branded)</SubTitle>
        <Item>
          <ol type="a">
            <li>
              Indicate the authorised representative liner: (Agency name) is an
              authorised representative of AIA Singapore Private Limited (Reg.
              No. 201106386R)
            </li>
            <li>
              Title of the position must be indicated clearly as AIA Financial
              Services Consultants
            </li>
            <li>
              Minimum full certificate in GCE ‘A’ Level, International
              Baccalaureate Diploma qualification, diploma awarded by a
              polytechnic in Singapore or equivalent.*
            </li>
            <li>
              Minimum age requirement of the position is at least 21 years
            </li>

            <li>
              There must be no mention of nationality, gender or race in your
              advertisements
            </li>
            <li>
              Advertisements must not specify any financing schemes (eg.) AIA
              Entrepreneur Power Scheme (EPS) / AIA Foreign Talent Scheme
              (FORTS)
            </li>
            <li>
              Should you need to include your email address, please only use the
              AIA branded email (e.g. nicoletan@aia.com.sg)
            </li>

            <li>
              A standard template for recruitment advertisement has been
              developed and you are strongly encouraged to use. Please refer to
              the template posted in Agent Internet Access for your use.
            </li>
          </ol>
          <FontBody style={{ color: "#000" }}>
            *Further specific requirements may apply to determine equivalent
            academic qualifications.
          </FontBody>
        </Item>
      </Wrapper>
    );
  };

  const renderContent = () => {
    if (type === 2) {
      return renderWebinarEdm();
    } else if (type === 3 && channel === "AGY") {
      return renderSocialMediaAccount();
    } else if (type === 4 && channel === "AGY") {
      return agyRecruitmentMaterial();
    } else if (type === 5 && channel === "AGY") {
      return renderContactSurvey();
    } else if (type === 3 && channel === "AFA") {
      return renderSocialMediaAccountSetup();
    } else if (type === 4 && channel === "AFA") {
      return renderAllRecruitmentMaterial();
    } else if (type === 5 && channel === "AFA") {
      return renderContactSurveyRegForm();
    } else if (type === 6) {
      return renderWebsiteCheklist(channel);
    } else if (type === 7) {
      return renderAdvertisingContentType(channel);
    } else if (type === 9) {
      return otherMarketingMaterial();
    }
  };

  const handleCancel = () => {
    setFieldCheck("");
    onCancel();
  };

  const isDisableNextButton = () => {
    if (agencyMarketingMaterial !== null) {
      return true;
    } else if (fieldCheck === "") {
      return true;
    } else if (fieldCheck === "No") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Modal
        visible={visible}
        {...props}
        footer={null}
        onCancel={() => {
          handleCancel();
        }}
        width={700}
      >
        <TopRow>
          <Title>Agency Marketing Material</Title>
        </TopRow>

        <Content>
          {renderContent()}

          <Footer>
            <ToggleButton
              setFieldValue={(_, text) => {
                setFieldCheck(text);
              }}
              labelOne="Yes"
              labelTwo="No"
              name="checklist"
              selectedValue={fieldCheck}
            />
          </Footer>
        </Content>
        <Bottom>
          <Button
            style={{
              marginTop: 20,
            }}
            width={240}
            type="primary"
            disabled={isDisableNextButton()}
            onClick={() => {
              onSubmit(fieldCheck);
            }}
          >
            {agencyMarketingMaterial !== null ? "OK" : "Next"}
          </Button>
        </Bottom>
      </Modal>
    </>
  );
}

ModalConsent.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.number.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  agencyMarketingMaterial: PropTypes.string,
  channel: PropTypes.string.isRequired,
};

ModalConsent.defaultProps = {
  visible: false,
  type: "edm",
  onCancel: () => {},
  onSubmit: () => {},
  agencyMarketingMaterial: null,
  channel: "AGY",
};

export default ModalConsent;
