import styled from "styled-components";

export const Container = styled.div`
  width: 44px;
  height: 44px;
  padding: 13px;
  background-color: #f7f8fa;
  border-radius: 100px;
`;

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

export const Elipse = styled.div`
  width: 7px;
  height: 7px;
  margin: 2.5px 0 8.5px 10.5px;
  border: solid 1px #ffffff;
  background-color: #f35162;
  border-radius: 100px;
  margin-left: -8px;
`;
