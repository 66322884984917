import { Button } from "antd";
import styled from "styled-components";
import { theme } from "../../../utils/constants";
import { FontBodyStrong } from "../../../utils/font-styles";

export const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 1.5px 13px 0 rgba(0, 0, 0, 0.09);
  border: solid 0.5px #c1c1c1;
  padding: 10px;
  margin-top: 12px;

  .ck-editor__editable {
    min-height: 120px !important;
  }
`;

export const Title = styled(FontBodyStrong)``;


export const ButtonComment = styled(Button) `
  margin-left: 10px;
  margin-top: 10px;
  background-color: ${(props) => (props.active ? theme.color.p1 : theme.color.c0)};
  color: #ffffff;
  cursor: pointer;
  border: none;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${(props) => (props.active ? theme.color.p1 : theme.color.c0)};
  }
  &:active, &:focus {
    background-color:  background-color: ${(props) => (props.active ? theme.color.p1 : theme.color.c0)};
  }
  &:disabled {
    transform: scale(1);
    opacity: 0.3;
    filter: alpha(opacity=40);
  }
  border-radius: 2px;
`;

export const ActionWrapper = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const Wrapper = styled.div`
  margin-top: 20px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
