import React from "react";
import PropTypes from "prop-types";
import Overlay from "./../Overlay";
import {
  Container,
  Wrapper,
  BottomWrapper,
  ViewScore,
  TextStatus,
  ViewBottom,
  TextStyled,
  ViewAi,
} from "./_scoreModal";
import { FontBodyStrong } from "./../../../utils/font-styles";
import moment from "moment";

const dateFormat = "MMMM, DD YYYY";
const timeFormat = "HH:mm:ss";

function ScoreModal(props) {
  const { visible, onCancel, item, page } = props;
  const {
    title,
    type,
    ai_score,
    purpose,
    platform,
    usage,
    create_date,
    comment,
    submissionNo,
    permitNo,
  } = item;

  return (
    <Overlay visible={visible} {...props} footer={null} onCancel={onCancel}>
      <Container>
        <Wrapper>
          <FontBodyStrong>{title}</FontBodyStrong>

          <table style={{ marginTop: 10 }}>
            <tr>
              <td>
                <TextStyled>Type</TextStyled>
              </td>
              <td>
                <TextStyled>:</TextStyled>
              </td>
              <td>
                <TextStyled>{type}</TextStyled>
              </td>
            </tr>
            <tr>
              <td>
                <TextStyled>Purpose</TextStyled>
              </td>
              <td>
                <TextStyled>:</TextStyled>
              </td>
              <td>
                <TextStyled>{purpose}</TextStyled>
              </td>
            </tr>
            <tr>
              <td>
                <TextStyled>Platform</TextStyled>
              </td>
              <td>
                <TextStyled>:</TextStyled>
              </td>
              <td>
                <TextStyled>{platform}</TextStyled>
              </td>
            </tr>
            <tr>
              <td>
                <TextStyled>Usage</TextStyled>
              </td>
              <td>
                <TextStyled>:</TextStyled>
              </td>
              <td>
                <TextStyled>{usage}</TextStyled>
              </td>
            </tr>
          </table>
        </Wrapper>
        <ViewScore>
          <ViewAi>
            <FontBodyStrong style={{ textAlign: "center" }}>
              AI Score
            </FontBodyStrong>
            {ai_score}
          </ViewAi>
        </ViewScore>
      </Container>
      <BottomWrapper>
        <ViewBottom>
          <TextStyled>Date</TextStyled>
          <TextStyled>{moment(create_date).format(dateFormat)}</TextStyled>
        </ViewBottom>
        <ViewBottom>
          <TextStyled>Time</TextStyled>
          <TextStyled>{moment(create_date).format(timeFormat)}</TextStyled>
        </ViewBottom>
        <ViewBottom>
          <TextStyled>Submission No</TextStyled>
          <TextStyled>{submissionNo}</TextStyled>
        </ViewBottom>
        <ViewBottom>
          <TextStyled>Permit No</TextStyled>
          <TextStyled>{permitNo}</TextStyled>
        </ViewBottom>
      </BottomWrapper>
      <br />
      <TextStyled>Comment : {comment}</TextStyled>

      <hr />
      <TextStatus>{page}</TextStatus>
    </Overlay>
  );
}

ScoreModal.propTypes = {
  visible: PropTypes.bool,
  item: PropTypes.object,
  onCancel: PropTypes.func,
};

ScoreModal.defaultProps = {
  visible: false,
  item: {},
  onCancel: () => {},
};

export default ScoreModal;
