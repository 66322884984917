import React from "react";
import ContentInstance from "../ContentInstance";
import QuickStats from "../QuickStats";
import {
  Container,
  LargeTitle,
  TopHeader,
  WelcomeLabel,
} from "./_DashboardLeftSIdeStyle";

const DashboardLeftSide = ({ allStats, instances }) => {
  return (
    <Container>
      <TopHeader>
        <LargeTitle>Dashboard</LargeTitle>
        <WelcomeLabel>Welcome Back!</WelcomeLabel>
      </TopHeader>
      <QuickStats
        totalMedia={allStats?.total_media}
        pendingApproval={allStats?.pending_approval}
        mediaShare={allStats?.media_share}
        approvedMedia={allStats?.approved_media}
      />

      <ContentInstance data={instances} showHeader={true} />
    </Container>
  );
};

export default DashboardLeftSide;
