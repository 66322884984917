import styled from "styled-components";
import { theme } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
`;

export const Wrapper = styled.button`
  text-align: center;
  align-items: center;
  background-color: ${(props) => (props.active ? theme.color.p1 : "#eaeaea")};
  width: 140px;
  height: 40px;
  color: ${(props) => (props.active ? "#fff" : "#000")};
  cursor: pointer;
  border: ${(props) => (props.active ? "none" : "solid 0.8px #000")};
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.9;
    background: ${theme.color.p1};
    border: none;
  }
  &:disabled {
    transform: scale(1);
    opacity: 0.3;
    filter: alpha(opacity=40);
  }
  border-radius: 20px;
  margin-left: 20px;
`;

export const Child = styled.div`
  text-align: center;
  &:hover {
    opacity: 0.9;
    color: #fff;
  }
`;
