import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "@reach/router";
import { Layout, Menu, Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../utils/constants";
import { MenuItem } from "../Sideitem";

const { confirm } = Modal;

const { Sider } = Layout;
const LogoWithoutLabel = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoutLabel = styled.div`
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSider = styled(Sider)`
  && {
    background-color: ${({ theme }) => theme.color.w};
  }
`;

function Sidebar(props) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { instance, accounts, inProgress } = useMsal();

  const onCollapse = () => {
    setCollapsed((state) => !state);
  };

  const showConfirmLogout = () => {
    confirm({
      title: "Are you sure want to logout?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        instance.logoutRedirect();
      },
      onCancel() {},
    });
  };

  return (
    <StyledSider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <LogoWithoutLabel>
        <FontAwesomeIcon
          icon={"share-square"}
          size="3x"
          color={theme.color.p1}
        />
      </LogoWithoutLabel>
      <Menu defaultSelectedKeys={[location.pathname]} mode="inline">
        <MenuItem
          key="/app/dashboard"
          title="Dashboard"
          icon={"th-large"}
          to="/app/dashboard"
        />
        <MenuItem
          key="/app/upload-media"
          title="Upload"
          icon={"upload"}
          to="/app/upload-media"
        />
        <MenuItem
          key="/app/pending-approval "
          title="Pending Approval "
          icon={"clock"}
          to="/app/pending-approval"
        />
        <MenuItem
          key="/app/approved"
          title="Approved"
          icon={"check-square"}
          to="/app/approved"
        />
        <MenuItem
          key="/app/rejected"
          title="Rejected"
          icon={"times"}
          to="/app/rejected"
        />
      </Menu>
      <LogoutLabel>
        <FontAwesomeIcon
          icon={"sign-out-alt"}
          size="2x"
          color={theme.color.p1}
          rotation={180}
          onClick={() => {
            showConfirmLogout();
          }}
        />
      </LogoutLabel>
    </StyledSider>
  );
}

export default Sidebar;
