import React from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";

function Overlay(props) {
  const {
    ModalComponent,
    visible,
    children,
    title,
    footer,
    closable,
    maskClosable,
    width,
    keyboard,
    onOk,
    onCancel,
    ...rest
  } = props;

  return (
    <ModalComponent
      visible={visible}
      title={title}
      footer={footer}
      onOk={onOk}
      onCancel={onCancel}
      closable={closable}
      maskClosable={maskClosable}
      width={width}
      keyboard={keyboard}
      {...rest}
    >
      {children}
    </ModalComponent>
  );
}

Overlay.propTypes = {
  ModalComponent: PropTypes.elementType.isRequired,
  children: PropTypes.element.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  footer: PropTypes.node,
  closable: PropTypes.bool.isRequired,
  maskClosable: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  keyboard: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

Overlay.defaultProps = {
  ModalComponent: Modal,
  title: "",
  closable: true,
  maskClosable: true,
  width: 520,
  keyboard: true,
  onOk: () => {},
  onCancel: () => {},
};

export default Overlay;
