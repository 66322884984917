import { DatePicker, Form, Select, Button as ButtonAnt } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { INSTANCE_STATUS } from "../../../utils/constants";
import {
  FontBody,
  FontBodyMedium,
  InfoFontBody,
} from "../../../utils/font-styles";
import {
  channels,
  contentType,
  platform,
  purpose,
} from "./../../../utils/data.json";
import {
  Container,
  ItemDetail,
  FooterActionView,
  AgencyRow,
  InfoCircleOutlined,
} from "./_uploadFormStyle";
import { UploadStyled, UploadButton, Overlay, Button, TextInput } from "..";
import { getBase64 } from "./../../../utils/file";

const dateFormat = "DD/MM/YYYY";
const UploadForm = ({
  onSubmitForm,
  initialValues,
  isPreview,
  isSubmitToReview,
  onSubmitToReview,
  onDeleteInstance,
  instanceStatus,
  onAgentMaterial,
  onResubmit,
}) => {
  const [form] = Form.useForm();
  const [showDate, setShowDate] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewState, setPreviewState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });

  const formRef = React.createRef();

  const [isResubmit, setIsResubmit] = useState(false);

  const [localPlatformData, setLocalPlatformData] = useState(platform);

  const [selectedPlatformValues, setSelectedPlatformValues] = useState([]);

  const onFinish = (values) => {
    onSubmitForm(values, values?.purpose);
  };

  const onFinishFailed = (errorInfo) => {};

  const handleChangeDate = (date, dateString) => {};

  const normFile = (e) => {
    setFileList(e?.fileList);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleChangeUpload = (upload) => {};

  const handleChangeUploadResubmit = (upload) => {
    setFileList(upload?.fileList);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewState({
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      previewImage: file.url || file.preview,
    });
  };

  const handleCancelPreview = () => {
    setPreviewState({ previewVisible: false });
  };

  const handleOnResubmit = () => {
    const values = {
      files: fileList,
      instanceID: initialValues?.id,
    };
    onResubmit(values);
  };

  const renderPreviewMode = () => {
    return (
      <>
        {/* <ButtonAnt
          type="default"
          onClick={() => {
            onAgentMaterial();
          }}
          style={{ marginTop: 10, marginBottom: 10, width: 130 }}
        >
          View Checklist
        </ButtonAnt> */}
        <InfoFontBody>
          You can click on the thumbnail for more details.
        </InfoFontBody>
        {instanceStatus === INSTANCE_STATUS.Rejected && (
          <>
            <FontBodyMedium>Add Additional Content</FontBodyMedium>
            <UploadStyled
              listType="picture-card"
              fileList={fileList}
              beforeUpload={true}
              action=""
              onChange={handleChangeUploadResubmit}
              onPreview={handlePreview}
            >
              {fileList.length >= 4 ? null : <UploadButton />}
            </UploadStyled>
          </>
        )}

        <ItemDetail>
          <FontBody>Title</FontBody>
          <FontBodyMedium>{initialValues?.title}</FontBodyMedium>
        </ItemDetail>
        <ItemDetail>
          <FontBody>Purpose</FontBody>
          <FontBodyMedium>{initialValues?.purpose}</FontBodyMedium>
        </ItemDetail>
        <ItemDetail>
          <FontBody>Platform</FontBody>
          <FontBodyMedium>{initialValues?.platform}</FontBodyMedium>
        </ItemDetail>
        <ItemDetail>
          <FontBody>Usage</FontBody>
          <FontBodyMedium>{initialValues?.usage}</FontBodyMedium>
        </ItemDetail>
        {initialValues?.refNo && (
          <ItemDetail>
            <FontBody>Submission Number</FontBody>
            <FontBodyMedium>{initialValues?.refNo}</FontBodyMedium>
          </ItemDetail>
        )}
        {initialValues?.permitNumber && (
          <ItemDetail>
            <FontBody>Approval Code</FontBody>
            <FontBodyMedium>{initialValues?.permitNumber}</FontBodyMedium>
            <FontBody style={{ color: "#000" }}>
              approval code to be included on material
            </FontBody>
          </ItemDetail>
        )}

        {initialValues?.agencyMarketingMaterial && (
          <ItemDetail>
            <AgencyRow>
              <FontBody>Agency Marketing Material</FontBody>
              <InfoCircleOutlined onClick={() => onAgentMaterial()} />
            </AgencyRow>

            <FontBodyMedium>
              {initialValues?.agencyMarketingMaterial}
            </FontBodyMedium>
          </ItemDetail>
        )}
        <FooterActionView>
          {isSubmitToReview && instanceStatus === INSTANCE_STATUS.UnSubmitted && (
            <>
              <Button
                secondary
                onClick={() => {
                  onDeleteInstance();
                }}
              >
                Delete
              </Button>
              <Button
                containerStyle={{ marginLeft: 10 }}
                type="primary"
                onClick={() => {
                  onSubmitToReview();
                }}
              >
                Submit To Review
              </Button>
            </>
          )}
          {instanceStatus === INSTANCE_STATUS.Rejected && (
            <Button
              containerStyle={{ marginLeft: 10 }}
              type="primary"
              disabled={fileList.length === 0}
              onClick={() => {
                handleOnResubmit();
              }}
            >
              Re-Submit
            </Button>
          )}
        </FooterActionView>
      </>
    );
  };

  const disabledDate = (current) => {
    return current && current < moment().add(14, "days");
  };

  const handlePlatfromChange = (value) => {
    setSelectedPlatformValues(value);
  };

  const handlePurposeChange = (value) => {
    const naValue = platform.filter((e) => e.id === 9);
    const naValueString = naValue[0].name;
    if (value === 1) {
      setLocalPlatformData(naValue);
      formRef.current.setFieldsValue({ platform: naValueString });
      setSelectedPlatformValues([naValueString]);
    } else {
      setLocalPlatformData(platform.filter((e) => e.id !== 9));
      setSelectedPlatformValues(
        selectedPlatformValues.filter((e) => e != naValueString)
      );
      formRef.current.setFieldsValue({
        platform: selectedPlatformValues.filter((e) => e != naValueString),
      });
    }
  };

  return (
    <Container>
      {isPreview ? (
        renderPreviewMode()
      ) : (
        <Form
          ref={formRef}
          initialValues={initialValues}
          form={form}
          layout="vertical"
          name="form_in_modal"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {!isPreview && (
            <Form.Item
              name="files"
              label="File Contents"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <UploadStyled
                listType="picture-card"
                fileList={fileList}
                beforeUpload={true}
                action=""
                onChange={handleChangeUpload}
                onPreview={handlePreview}
              >
                {fileList.length >= 4 ? null : <UploadButton />}
              </UploadStyled>
            </Form.Item>
          )}

          <Form.Item
            label="Content Title"
            name="title"
            rules={[{ required: true, message: "Please enter a valid Title" }]}
          >
            <TextInput />
          </Form.Item>

          <Form.Item
            label="Purpose"
            name="purpose"
            rules={[
              {
                required: true,
                message: "Please select a valid Purpose",
              },
            ]}
          >
            <Select onChange={handlePurposeChange}>
              {purpose.map((res, j) => (
                <Select.Option key={j} value={res.id}>
                  {res.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Platform"
            name="platform"
            rules={[
              {
                required: true,
                message: "Please select a valid Platform",
              },
            ]}
          >
            <Select
              mode="multiple"
              defaultValue={selectedPlatformValues}
              onChange={handlePlatfromChange}
              value={selectedPlatformValues}
              allowClear
            >
              {localPlatformData.map((res, k) => (
                <Select.Option key={k} value={res.name}>
                  {res.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Usage Date" name="dateUsage">
            <DatePicker
              disabled={isPreview}
              format={dateFormat}
              style={{ width: "100%" }}
              size="middle"
              onChange={handleChangeDate}
              defaultPickerValue={moment().add(14, "days")}
              disabledDate={disabledDate}
            />
          </Form.Item>

          {!isSubmitToReview && (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Upload
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
      <Overlay
        visible={previewState?.previewVisible}
        title={previewState?.previewTitle}
        footer={null}
        onCancel={handleCancelPreview}
      >
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewState.previewImage}
        />
      </Overlay>
    </Container>
  );
};

UploadForm.propTypes = {
  initialValues: PropTypes.object,
  isPreview: PropTypes.bool,
  isSubmitToReview: PropTypes.bool,
};

UploadForm.defaultProps = {
  initialValues: null,
  isPreview: false,
  isSubmitToReview: false,
};

export default UploadForm;
