import React from "react";
import {
  Container,
  BgImage,
  BadgeText,
  MoreText,
  CrownStemp,
  CrownImage,
} from "./_profileBadgeStyle";
import CrownIcon from "./assets/crown.svg";
function ProfileBagde() {
  return (
    <Container>
      <BgImage>
        <div>
          <BadgeText>Profile Badge</BadgeText>
          <MoreText>For more Profile Control</MoreText>
        </div>
        <CrownStemp>
          <CrownImage src={CrownIcon} />
        </CrownStemp>
      </BgImage>
    </Container>
  );
}

export default ProfileBagde;
