import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchApprovedInstances } from "../../redux/agent/actions";
import ContentInstance from "../ContentInstance";
import { Col, Row } from "../Grid";
import Layout from "../Layout";
import { PageHeader } from "../UI";

function CompletedPage() {
  const dispatch = useDispatch();
  const { approvedInstances, user } = useSelector((state) => {
    return {
      approvedInstances: state.agent.approvedInstances,
      user: state.auth.user
    };
  }, shallowEqual);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    dispatch(fetchApprovedInstances());
  };

  return (
    <Layout>
      <PageHeader title="Approved" user={user}/>
      <Row type="flex" justify="center">
        <Col xs={17} lg={17} xl={17} sm={17}>
          <ContentInstance data={approvedInstances} />
        </Col>
      </Row>
    </Layout>
  );
}

export default CompletedPage;
