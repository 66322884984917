import styled from "styled-components";
import { theme } from "../../utils/constants";

export const H2 = styled.h2`
  text-align: center;
  color: ${theme.color.p1};
  font-weight: 500;
  text-transform: uppercase;
`;

export const SplashView = styled.div`
  background-color: ${theme.color.p1};
  width: 100%;
  height: 100%;
  position: absolute;
`;
