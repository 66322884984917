import styled from 'styled-components';

const Container = styled.div`
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 20px 100px;

    @media (max-width: 1024px) {
        padding: 20px 60px;
    }
    @media (max-width: 767px) {
        padding: 20px 28px;
    }

    ${({ height }) => height && `
        height: ${height};
    `}
    ${({ justify }) => justify && `
        justify-content: ${justify};
    `}
    ${({ align }) => align && `
        align-items: ${align};
    `}
    ${({ margin }) => margin && `
        margin: ${margin};
    `}
    ${({ padding }) => padding && `
        padding: ${padding};
    `}
    ${({ display }) => display && `
        display: ${display};
    `}
    ${({ textalign }) => textalign && `
        text-align: ${textalign};
    `}
    ${({ border }) => border && `
        border: ${border};
    `}
    ${({ borderTop }) => borderTop && `
    border-top: ${borderTop};
`}
    ${({ borderBottom }) => borderBottom && `
    border-bottom: ${borderBottom};
`}
    ${({ bgcolor, theme }) => bgcolor && `
        background-color: ${theme.color[bgcolor]};
    `}

`;

export default Container;
