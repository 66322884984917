import { Button } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  background-color: white;
  padding-bottom: 40px;
`;

export const Wrapper = styled.div`
  width: 592px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(195, 60, 84, 0.5);
  margin-bottom: 11px;
`;

export const ButtonStyled = styled(Button)`
  background-color: ${(props) => (props.isReady ? "#00a72d" : "#ff3b30")};
  color: white;
  &:hover {
    background-color: ${(props) => (props.isReady ? "#00a72d" : "#ff3b30")};
    color: white;
  }
`;
export const LabelFile = styled.div`
  line-height: 1.29;
  letter-spacing: -0.41px;
  text-align: left;
  color: #000000;
  align-self: center;
  padding-left: 10px;
`;

export const ButtonUpload = styled.button`
  width: 335px;
  padding: 7px 143.5px;
  border-radius: 30px;
  background-color: #d31145;
  color: white;
  border: 0;
  cursor: pointer;
  margin-top: 20px;
  align-self: center;
`;
