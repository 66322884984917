import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestLogin } from "../../redux/auth/actions";
import { KEY_STORAGE_TOKEN } from "../../utils/constants";
import { Col, Row } from "../Grid";
import Container from "./../Container";
import Layout from "./../Layout";
import { Button, Loading, Logo } from "./../UI";
import { H2 } from "./_loginStyle";

const Login = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { loading, loginSuccess } = useSelector((state) => {
    return {
      loading: state.auth.loading,
      loginSuccess: state.auth.loginSuccess,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      const request = {
        scopes: ["User.Read"],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(request)
        .then((response) => {
          if (response) {
            sessionStorage.setItem(KEY_STORAGE_TOKEN, response?.accessToken);
            onRequestLogin(response.accessToken);
          }
        })
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            instance.loginRedirect();
          }
          if (error instanceof BrowserAuthError) {
            instance.loginRedirect();
          }
        });
    }
  }, [accounts, instance]);

  const onRequestLogin = (accessToken) => {
    dispatch(
      requestLogin({
        accessToken,
      })
    );
  };

  useEffect(() => {
    if (loginSuccess) {
      goToDashboard();
    }
  }, [loginSuccess]);

  const goToDashboard = () => {
    navigate("/app/dashboard", { replace: true });
  };

  if (inProgress === "handleRedirect" || loading) {
    return (
      <Layout withSidebar={false} withFooter>
        <Container>
          <Loading />
        </Container>
      </Layout>
    );
  }
  if (!isAuthenticated) {
    return (
      <Layout withSidebar={false} withFooter>
        <Container>
          <Row
            type="flex"
            justify="center"
            style={{ alignItems: "center", flexDirection: "column" }}
          >
            <Col>
              <Logo containerStyle={{ marginBottom: 12, marginTop: 12 }} />
              <H2>Log In</H2>
            </Col>
            <Col>
              <Button onClick={() => instance.loginRedirect()} width={240}>
                LOGIN WITH MICROSOFT
              </Button>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
  return null;
};

Login.propTypes = {};

export default Login;
