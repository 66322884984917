import moment from "moment";
import { BASE_API, KEY_STORAGE_TOKEN } from "./constants";
import axios from "axios";
import FileSaver from "file-saver";
export const GetToken = () => {
  const token = sessionStorage.getItem(KEY_STORAGE_TOKEN);
  return token || null;
};

export const HumanizeDate = (date) => {
  if (!date) return "-";
  return moment(date).fromNow(true);
};

export const FormatRequestDate = (date) => {
  if (!date) return "-";
  return moment(date).format("YYYY-MM-DD");
};

export const CommentDate = (date) => {
  if (!date) return "-";
  return moment(date).format("MMMM, DD YYYY HH:mm:ss");
};

export const DownloadProtectedMedia = (token, path, reqConfig = {}) => {
  if (path) {
    const config = {
      method: "get",
      url: path,
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
      ...reqConfig,
    };
    return axios(config)
      .then((res) => {
        if (res.data) {
          return URL.createObjectURL(res.data);
        }
        return null;
      })
      .catch(() => null);
  }
};

export const CheckMediaType = (type) => {
  switch (type) {
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/tiff":
      return "Image";
    case "video/3gpp":
    case "video/x-msvideo":
    case "video/mp4":
    case "video/mpeg":
      return "Video";
    case "application/pdf":
      return "Doc";
    default:
      return null;
  }
};

export const fileSave = (token, path, reqConfig = {}, fileName) => {
  if (path) {
    const config = {
      method: "get",
      url: path,
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
      ...reqConfig,
    };
    return axios(config)
      .then((response) => {
        if (response.data) {
          const blob = new Blob([response.data]);
          FileSaver.saveAs(blob, fileName);
        }
        return null;
      })
      .catch(() => null);
  }
};
