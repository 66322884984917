import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SessionTimeout } from "../../utils/SessionTimeout";

const Secured = ({ component: Component, ...props }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  useEffect(() => {
    if (!isAuthenticated) {
      redirectToLogin();
    }
  }, [isAuthenticated]);

  const redirectToLogin = () => {
    navigate("/app/auth-verify", { replace: true });
  };

  if (isAuthenticated) {
    return (
      <>
        <SessionTimeout
          isAuthenticated={isAuthenticated}
          logOut={() => {
            instance.logoutRedirect();
          }}
        />
        <Component {...props} />;
      </>
    );
  }

  return null;
};

Secured.propTypes = {
  component: PropTypes.elementType.isRequired,
};

Secured.defaultProps = {};

export default Secured;
