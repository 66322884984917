import styled from "styled-components";
import { FontBody } from "../../../utils/font-styles";

export const Container = styled.div`
  background-color: white;
  padding: 0 26px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
`;

export const ViewAll = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 2;
`;

export const AllText = styled(FontBody)`
  color: #ffa101;
  padding-right: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
`;
