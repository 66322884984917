import React from "react";
import PropTypes from "prop-types";
import {
  ApproveIcon,
  RejectIcon,
  CommentIcon,
  Container,
} from "./_buttonIconStyle";

function ButtonIcon(props) {
  const { children, type } = props;

  const renderIcon = (iconType) => {
    if (iconType == "reject") {
      return <RejectIcon />;
    } else if (iconType === "comment") {
      return <CommentIcon />;
    } else {
      return <ApproveIcon />;
    }
  };

  return (
    <Container {...props}>
      {renderIcon(type)} {children}
    </Container>
  );
}

ButtonIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  type: PropTypes.oneOf(["approve, reject, comment"]),
  width: PropTypes.number,
  disabled: PropTypes.bool,
};

ButtonIcon.defaultProps = {
  children: "Button",
  type: "approve",
  width: null,
  disabled: false,
};

export default ButtonIcon;
