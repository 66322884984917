import styled from "styled-components";
import { Card } from "antd";
import { FontBody, FontBodyMedium } from "../../../utils/font-styles";

export const CardWrapper = styled(Card)`
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.25);
  background-color:'red',
  padding: 0px;
  height : 240px;
`;

export const Container = styled.div`
  &:hover: {
    cursor: "pointer";
  }
`;

export const ContentThumbnail = styled.img`
  width: 100%;
  height: 170px;
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const TitleLabel = styled(FontBodyMedium)`
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
`;

export const PostLabel = styled(FontBody)`
  color: #000;
  padding-top: 8px;
`;
