import { Router } from "@reach/router";
import React from "react";
import ApprovedPage from "../components/ApprovedPage";
import CompletedPage from "../components/CompletedPage";
import DashboardPage from "../components/Dashboard";
import LoginPage from "../components/Login";
import Secured from "../components/Secured";
import UploadFilesPage from "../components/UploadFiles";
import UploadMediaPage from "../components/UploadMedia";
import PendingPage from "./../components/PendingPage";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { AZURE_MSAL_CONFIG } from "../utils/constants";

const App = () => {
  const pca = new PublicClientApplication(AZURE_MSAL_CONFIG);
  return (
    <MsalProvider instance={pca}>
      <Router>
        <LoginPage path="/app/auth-verify" />
        <Secured path="/app/dashboard" component={DashboardPage} />
        <Secured path="/app/upload-media" component={UploadMediaPage} />
        <Secured path="/app/upload-files" component={UploadFilesPage} />
        <Secured path="/app/pending-approval" component={ApprovedPage} />
        <Secured path="/app/approved" component={CompletedPage} />
        <Secured path="/app/rejected" component={PendingPage} />
      </Router>
    </MsalProvider>
  );
};

export default App;
