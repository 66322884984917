import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const CustomSpinner = () => (
  <Spin
    indicator={antIcon}
    size="large"
    style={{ marginLeft: 160, marginTop: 50 }}
  />
);

export default CustomSpinner;
