import styled from "styled-components";
import { FontHdrLargeStronger } from "../../../utils/font-styles";

export const PageTitle = styled(FontHdrLargeStronger)`
  padding: 26px;
`;

export const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
`;
