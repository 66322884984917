import React from "react";
import PropTypes from "prop-types";
import { Container, Icon } from "./_socialIconsStyle";
import FbIcon from "./assets/facebook.svg";
import TwtIcon from "./assets/twitter.svg";
import YtbIcon from "./assets/youtube.svg";
import InsIcon from "./assets/instagram.svg";
function SocialIcons(props) {
  const { icons, onClick, containerStyle } = props;
  const handleClick = (idx) => {
    onClick(idx);
  };
  return (
    <Container style={containerStyle}>
      {icons.map((icon, i) => (
        <Icon onClick={() => handleClick(i)} src={icon} />
      ))}
    </Container>
  );
}
SocialIcons.propTypes = {
  icons: PropTypes.arrayOf(String).isRequired,
  onClick: PropTypes.func.isRequired,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SocialIcons.defaultProps = {
  icons: [FbIcon, TwtIcon, YtbIcon, InsIcon],
  onClick: () => {},
  containerStyle: null,
};
export default SocialIcons;
