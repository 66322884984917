import styled from "styled-components";
import { theme } from "../../../utils/constants";
import { FontBodyStronger } from "../../../utils/font-styles";

export const TopRow = styled.div`
  text-align: center;
  padding-bottom: 20px;
  padding-left: 20px;
`;

export const Title = styled(FontBodyStronger)``;

export const SubTitle = styled.h4`
  text-decoration: underline;
`;

export const Item = styled.div`
  ol {
    width: 100%;
    padding-left: 18px;
  }
  ol > li {
    margin-bottom: 10px;
    font-family: Arial;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  padding: 0 20px;
`;

export const Content = styled.div`
  padding: 10px 0px;
  border-radius: 20px;
  border: solid 0.8px ${theme.color.p1};
`;

export const ItemTable = styled.div`
  table,
  th,
  td {
    border: 0.5px solid #000;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 15px;
  }

  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
`;
