import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  MessageView,
  TitleText,
  IconType,
  Icon,
} from "./_itemStyle";
import { FontBody } from "../../../utils/font-styles";
import emailIcon from "./assets/email.svg";
import exclamationIcon from "./assets/exclamation-mark.svg";
function Item(props) {
  const { type, title, message } = props;

  const showIcon = () => {
    if (type === "new") {
      return emailIcon;
    }

    if (type === "reminder") {
      return exclamationIcon;
    }
  };

  return (
    <Container>
      <IconType type={type}>
        <Icon src={showIcon()} alt="inc" />
      </IconType>
      <MessageView>
        <TitleText>{title}</TitleText>
        <FontBody>{message}</FontBody>
      </MessageView>
    </Container>
  );
}

Item.propTypes = {
  type: PropTypes.oneOf(["reminder,new"]).isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

Item.defaultProps = {
  type: "reminder",
  title: "Booking Reminder",
  message: "Lorem ipsum dolor sit amet, consetetur",
};

export default Item;
