import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  YourLabel,
  Wrapper,
  View,
  Label,
} from "./_yourUploadsStyles";
import { FontBody } from "../../../utils/font-styles";
import styled from "styled-components";

const InfoStaticFont = styled(FontBody)`
  color: #ff0000;
  font-size: 14px;
`;

function YourUploads(props) {
  const { total, type } = props;

  return (
    <Container>
      <InfoStaticFont>
        AI has scored your submissions and provided recommendations. You must
        click on each upload and submit to the relevant approver for review.
      </InfoStaticFont>
      <Wrapper>
        <YourLabel>Your Upload</YourLabel>
        <View>
          <FontBody>{total}</FontBody>
          <Label>Submission</Label>
        </View>
      </Wrapper>
      <hr class="zig" />
      <hr class="zag" />
    </Container>
  );
}

YourUploads.propTypes = {
  total: PropTypes.string,
  type: PropTypes.string,
};

YourUploads.defaultProps = {
  total: 0,
};

export default YourUploads;
