import React, { useState, useEffect } from "react";
import CreatedBy from "./CreatedBy";
import {
  Container,
  Title,
  ActionWrapper,
  Wrapper,
  HeaderRow,
  ButtonComment,
} from "./_commentsStyle";

import { shallowEqual, useSelector } from "react-redux";
import { ImageButton } from "..";
import { INSTANCE_STATUS } from "../../../utils/constants";
import { Col, Row } from "antd";

const Comments = ({
  onSubmitComment,
  comments,
  status,
  onCancelComment,
  instanceID,
  contents,
  loadComments,
}) => {
  const { user } = useSelector((state) => {
    return {
      user: state.auth.user,
    };
  }, shallowEqual);
  const [data, setData] = useState("");
  const [buttonComments, setButtonComments] = useState([]);
  const [buttonCommentSelected, setButtonCommentSelected] = useState([]);

  const pushButtons = (contents) => {
    let buttons = [];
    contents.forEach((element) => {
      buttons.push({ id: element?.id, name: element?.name });
    });
    setButtonComments(buttons);
  };

  useEffect(() => {
    if (contents.length > 0) {
      pushButtons(contents);
    }
  }, [contents]);

  useEffect(() => {
    if (buttonComments.length > 0) {
      onReloadComment(buttonComments[0]);
    }
  }, [buttonComments]);

  const onSubmit = () => {
    if (data) {
      var params = {
        comment_txt: data,
      };
      if (buttonCommentSelected?.name != "ALL") {
        params.contentID = buttonCommentSelected?.id;
      } else {
        params.instanceID = buttonCommentSelected?.id;
      }
      onSubmitComment(params);
      setData("");
    }
  };

  const onReloadComment = (button) => {
    setButtonCommentSelected(button);
  };

  useEffect(() => {
    if (buttonCommentSelected) {
      var params = {};
      if (buttonCommentSelected?.name != "ALL") {
        params.contentID = buttonCommentSelected?.id;
      } else {
        params.instanceID = buttonCommentSelected?.id;
      }
      loadComments(params);
    }
  }, [buttonCommentSelected]);

  const statusAllowToComments = [
    INSTANCE_STATUS.INSTANCE_SUBMITTED,
    INSTANCE_STATUS.INSTANCE_PENDING_PRIMARY_STAFF,
    INSTANCE_STATUS.INSTANCE_PENDING_SECONDARY_STAFF,
  ];
  const isAllowtoComment = statusAllowToComments.includes(status);

  if (typeof window !== "undefined") {
    // prevent SSR from not recognizing the `window`.
    const { CKEditor } = require("@ckeditor/ckeditor5-react");
    const ClassicEditor = require("@ckeditor/ckeditor5-build-classic");

    return (
      <Container>
        <HeaderRow>
          <Col>
            <Row>
              {buttonComments.map((btn) => {
                return (
                  <ButtonComment
                    type="primary"
                    active={btn?.id === buttonCommentSelected?.id}
                    onClick={() => {
                      onReloadComment(btn);
                    }}
                  >
                    {btn?.name}
                  </ButtonComment>
                );
              })}
            </Row>
          </Col>
        </HeaderRow>

        {comments.map((comment) => {
          return <CreatedBy comment={comment} />;
        })}
        {isAllowtoComment && (
          <Wrapper>
            <CKEditor
              editor={ClassicEditor}
              data={data}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setData(data);
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
            />
            <ActionWrapper>
              <ImageButton
                type="add"
                onClick={() => {
                  onSubmit();
                }}
              >
                Add Comment
              </ImageButton>
              <ImageButton
                type="cancel"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  setData("");
                  onCancelComment();
                }}
              >
                Cancel
              </ImageButton>
            </ActionWrapper>
          </Wrapper>
        )}
      </Container>
    );
  } else {
    return null;
  }
};

export default Comments;
