import styled from "styled-components";

export const Container = styled.div`
  margin: 10px;
`;

export const ContentThumbnail = styled.img`
    width: 100%;
    height: 150px;
    border-radius: 6px;
    margin-bottom: 10px;
    box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.25);
`;
