import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckSquare,
  faShareSquare,
  faThLarge,
  faTimes,
  faUpload,
  faClock,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faShareSquare,
  faThLarge,
  faUpload,
  faCheckSquare,
  faTimes,
  faClock,
  faSignOutAlt
);
