import { Radio } from "antd";
import styled from "styled-components";
import { InfoCircleOutlined as InfoCircleOutlinedAnt } from "@ant-design/icons";

export const Container = styled.div`
  margin-top: 24px;
  width: 592px;
  align-self: center;
`;
export const RadioButton = styled(Radio.Group)`
  /* && {
    &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      background-color: ${({ theme }) => theme.color.p1};
      border-color:  ${({ theme }) => theme.color.p1};
    }
  } */
`;

export const MediaProgressView = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 187px;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 187px;
  object-fit: scale-down;
`;

export const ItemDetail = styled.div`
  margin-bottom: 10px;
`;

export const FooterActionView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-left: 40px;
`;

export const AgencyRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const InfoCircleOutlined = styled(InfoCircleOutlinedAnt)`
  margin-left: 8px;
  color: ${({ theme }) => theme.color.p1};
  width: 20px;
`;
