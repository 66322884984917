import React from "react";
import PropTypes from "prop-types";
import { FontBodyStronger } from "../../../utils/font-styles";
import arrowRightIcon from "./assets/arrow-right.svg";
import Item from "./Item";
import { Container, Wrapper, AllText, ViewAll } from "./_notificationItemStyle";
import { dummyData } from "./dummyData";
function NotificationItem(props) {
  const { items, onViewAll } = props;
  const handleViewAll = () => {
    onViewAll();
  };
  return (
    <Container>
      <Wrapper>
        <FontBodyStronger>Notification</FontBodyStronger>
        <ViewAll>
          <AllText onClick={() => handleViewAll()}>View All</AllText>
          <img src={arrowRightIcon} alt="img" />
        </ViewAll>
      </Wrapper>
      {items.map((res) => (
        <Item type={res.type} title={res.title} message={res.message} />
      ))}
    </Container>
  );
}

NotificationItem.propTypes = {
  items: PropTypes.array,
  onViewAll: PropTypes.func,
};

NotificationItem.defaultProps = {
  items: dummyData,
  onViewAll: () => {},
};

export default NotificationItem;
