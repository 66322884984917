export const dummyData = [
  {
    type: "reminder",
    title: "Booking Reminder",
    message: "Lorem ipsum dolor sit amet, consetetur",
  },
  {
    type: "new",
    title: "New Message",
    message: "Lorem ipsum dolor sit amet, consetetur",
  },
  {
    type: "reminder",
    title: "Upcoming Booking",
    message: "Lorem ipsum dolor sit amet, consetetur",
  },
];
