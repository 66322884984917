import React from "react";
import PropTypes from "prop-types";
import { StyledButton } from "./_styleButton";

function Button(props) {
  const { children, containerStyle } = props;
  return (
    <StyledButton style={containerStyle} {...props}>
      {children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  secondary: PropTypes.bool,
  width: PropTypes.number,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ghost: PropTypes.bool,
};

Button.defaultProps = {
  children: "Button",
  secondary: false,
  width: null,
  containerStyle: null,
  ghost: false,
};

export default Button;
