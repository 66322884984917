import styled from "styled-components";
import {
  FontBodyMedium,
  FontBodyStrong,
  FontBody,
} from "../../../utils/font-styles";

export const Container = styled.div`
  margin-top: 24px;
`;

export const CardWrapper = styled.div`
  box-shadow: 0 2.5px 5px 0 rgba(0, 0, 0, 0.25);
  padding: ${(props) => (props.isRevised ? "10px 10px 30px 10px" : "10px")};
  // height: 240px;
`;

export const MediaContainer = styled.div`
  margin: 10px;
  height: 150px;
  background-color: "#000";
`;

export const MediaProgressView = styled.div`
  width: 100%;
  height: 187px;
  margin-top: 24px;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 187px;
  object-fit: scale-down;
`;

export const TitleFont = styled(FontBodyStrong)`
  text-align: center;
  padding-bottom: 12px;
`;

export const PostFont = styled(FontBodyMedium)`
  text-align: center;
  padding-bottom: 12px;
`;

export const ScoreView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`;

export const ImageShield = styled.img`
  width: 150px;
  height: 150px;
  object-fit: scale-down;
  position: absolute;
  top: 200px;
  right: 30px;
`;

export const ButtonCommentWrapper = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const NameLabel = styled(FontBody)`
  text-overflow: ellipsis;
  overflow: hidden;
  height: 1.2em;
  white-space: nowrap;
  margin-bottom: 6px;
`;
