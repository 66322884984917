import React, { useState } from "react";
import {
  WelcomeUser,
  SocialIcons,
  ProfileBadge,
  NotificationItem,
  SearchInput,
  BadgeNotification,
  Overlay,
} from "../UI";
import { Container, TopView } from "./_rightSideStyle";

function RightSide(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [socialIndex, setSocialIndex] = useState(null);
  const handleViewAll = () => {
    setIsModalVisible((prevProp) => !prevProp);
  };
  const handleSocialClick = (i) => {
    setSocialIndex(i);
    handleViewAll();
  };
  return (
    <Container>
      <TopView>
        {/* <SearchInput /> */}
        {/* <BadgeNotification count={3} /> */}
      </TopView>
      <WelcomeUser user={props?.user} />
      {/* <SocialIcons onClick={(i) => handleSocialClick(i)} /> */}
      {/* <ProfileBadge /> */}
      {/* <NotificationItem onViewAll={() => handleViewAll()} /> */}
      <Overlay
        visible={isModalVisible}
        onOk={() => handleViewAll()}
        onCancel={() => {
          handleViewAll();
        }}
      >
        {socialIndex !== null && <p>Some contents {socialIndex}</p>}
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Overlay>
    </Container>
  );
}

export default RightSide;
