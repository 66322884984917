import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0 26px 26px;
`;

export const Icon = styled.img`
  height: 57px;
  cursor: pointer;
`;
