import React from "react";
import Layout from "../Layout";
import { PageHeader, UploadForm } from "../UI";
import { Container } from "./_uploadFilesStyle";

function UploadFiles() {
  return (
    <Layout>
      <PageHeader title="Upload" />
      <Container>
        <UploadForm />
      </Container>
    </Layout>
  );
}

export default UploadFiles;
