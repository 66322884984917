import styled from "styled-components";
import { FontBody, FontHdrSmall } from "../../../utils/font-styles";
import BgPattern from "./assets/Pattern.svg";

export const Container = styled.div`
  background-color: white;
  padding: 0 26px 26px;
`;

export const BgImage = styled.div`
  display: flex;
  justify-content: space-between;
  height: 140px;
  background-image: url("${BgPattern}");
  background-color: #2f2d78;
  background-repeat: no-repeat;
  object-fit: contain;
  padding: 38px 37px 36.5px 25px;
  background-size: cover;
  border-radius: 15px;
`;

export const BadgeText = styled(FontHdrSmall)`
  color: white;
`;

export const MoreText = styled(FontBody)`
  color: white;
  padding-top: 28px;
`;

export const CrownStemp = styled.div`
width: 60px;
height: 60px;
border-radius: 20px;
background-color: #ffa101;
padding 17px 10px;
`;

export const CrownImage = styled.img`
  width: 40px;
  height: 40px;
`;
