import styled from 'styled-components';
import { Col } from 'antd';

const StyledCol = styled(Col)`
    && {
        ${({ height }) => height && `
            height: ${height};
        `}
        ${({ bgcolor }) => bgcolor && `
            background-color: ${bgcolor};
        `}
        ${({ textalign }) => textalign && `
            text-align: ${textalign};
        `}
        ${({ margin }) => margin && `
            margin: ${margin};
        `}
        ${({ padding }) => padding && `
            padding: ${padding};
        `}
    }
`;

export default StyledCol;
