import React, { useEffect } from "react";
import Layout from "./../Layout";
import { Col, Row } from "../Grid";
import RightSide from "../RightSide";
import DashboardLeftSide from "../DashboardLeftSide";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAllStats,
  fetchDocuments,
  fetchImages,
  fetchInstances,
  fetchVideos,
} from "../../redux/dashboard/actions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { instances, allStats, user } = useSelector((state) => {
    return {
      isFetchStats: state.dashboard.isFetchStats,
      instances: state.dashboard.instances,
      allStats: state.dashboard.allStats,
      videos: state.dashboard.videos,
      images: state.dashboard.images,
      documents: state.dashboard.documents,
      user: state.auth.user,
    };
  }, shallowEqual);

  useEffect(() => {
    fetchQuickStats();
    loadInstances();
    loadVideos();
    loadImages();
    loadDocuments();
  }, []);

  const fetchQuickStats = () => {
    dispatch(fetchAllStats());
  };

  const loadInstances = () => {
    dispatch(fetchInstances());
  };

  const loadVideos = () => {
    dispatch(fetchVideos());
  };

  const loadImages = () => {
    dispatch(fetchImages());
  };

  const loadDocuments = () => {
    dispatch(fetchDocuments());
  };

  return (
    <Layout>
      <Row type="flex" justify="space-between">
        <Col xs={16} lg={16} xl={16} sm={16}>
          <DashboardLeftSide allStats={allStats} instances={instances} />
        </Col>
        <Col xs={8} lg={8} xl={8} sm={8}>
          <RightSide user={user} />
        </Col>
      </Row>
    </Layout>
  );
};

export default Dashboard;
