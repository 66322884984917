import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Child, Wrapper } from "./_toggleButtonStyle";

function ToggleButton(props) {
  const {
    containerStyle,
    labelOne,
    labelTwo,
    setFieldValue,
    name,
    selectedValue,
  } = props;
  const [active, setActive] = useState(selectedValue);

  const checkStatus = (_name) => {
    if (active === _name) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setActive(selectedValue);
  }, [selectedValue]);

  const onPress = (val) => {
    setActive(val);
    setFieldValue(name, val);
  };

  return (
    <Container {...props} style={containerStyle}>
      <Wrapper onClick={() => onPress(labelOne)} active={checkStatus(labelOne)}>
        <Child>{labelOne}</Child>
      </Wrapper>
      <Wrapper onClick={() => onPress(labelTwo)} active={checkStatus(labelTwo)}>
        <Child>{labelTwo}</Child>
      </Wrapper>
    </Container>
  );
}

ToggleButton.propTypes = {
  labelOne: PropTypes.string,
  labelTwo: PropTypes.string,
  setFieldValue: PropTypes.func,
  selectedValue: PropTypes.string,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ToggleButton.defaultProps = {
  labelOne: "Yes",
  labelTwo: "No",
  setFieldValue: () => {},
  selectedValue: "",
  containerStyle: null,
};

export default ToggleButton;
