import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  requestUploadContent,
  resetStateAgent,
} from "../../redux/agent/actions";
import Layout from "../Layout";
import { ModalProgress, PageHeader, UploadForm, ModalConsent } from "./../UI";
import { Container } from "./_uploadMediaStyle";
import { COSENT_IDS } from "./../../utils/constants";
import { FormatRequestDate } from "./../../utils/helper";
import { purpose } from "./../../utils/data.json";

const { confirm } = Modal;
function UploadMedia() {
  const dispatch = useDispatch();
  const { isUploading, isSuccessUpload, user } = useSelector((state) => {
    return {
      isUploading: state.agent.isUploading,
      isSuccessUpload: state.agent.isSuccessUpload,
      user: state.auth.user,
    };
  }, shallowEqual);

  const [isConsent, setIsConsent] = useState({
    visible: false,
    type: 0,
    formValues: {},
  });

  useEffect(() => {
    if (isSuccessUpload) {
      showPopUpAfterUploadling();
    }
  }, [isSuccessUpload]);

  const backToDashboard = () => {
    dispatch(resetStateAgent());
    navigate("/app/dashboard", { replace: true });
  };

  const onSubmit = (values, purposeId) => {
    if (COSENT_IDS.findIndex((elm) => elm === purposeId) > -1) {
      setIsConsent({
        visible: true,
        type: purposeId,
        formValues: values,
      });
    } else {
      showConfirm(values);
    }
  };

  const setFormData = (values, agencyMarketingMaterial) => {
    const formData = new FormData();
    if (values?.files?.length > 0) {
      values?.files.forEach((file, i) => {
        formData.append(`files`, file.originFileObj, file.originFileObj.name);
      });
    }
    formData.append("title", values?.title);
    formData.append(
      "purpose",
      purpose.find((val) => val.id === values?.purpose).name
    );
    const parseToString = values?.platform?.toString() || "";
    formData.append("platform", parseToString);
    formData.append(
      "flowType",
      purpose.find((val) => val.id === values?.purpose).flowType
    );
    if (agencyMarketingMaterial) {
      formData.append("agencyMarketingMaterial", agencyMarketingMaterial);
    }

    formData.append("usage", FormatRequestDate(values?.dateUsage));

    return formData;
  };

  const showConfirm = (values, agencyMarketingMaterial) => {
    confirm({
      title: "Would you like to continue with your submission?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(
          requestUploadContent(setFormData(values, agencyMarketingMaterial))
        );
      },
      onCancel() {},
    });
  };

  const showPopUpAfterUploadling = () => {
    Modal.success({
      content:
        'Checked, set and click "OK" to submit!',
      onOk() {
        backToDashboard();
      },
    });
  };

  const renderConsentChannel = () => {
    return (
      <ModalConsent
        channel={user.channel}
        visible={isConsent.visible}
        type={isConsent.type}
        onSubmit={(amm) => {
          setIsConsent({
            visible: false,
            type: isConsent.type,
            values: isConsent.formValues,
          });
          setTimeout(() => {
            showConfirm(isConsent.formValues, amm);
          }, 500);
        }}
        onCancel={() => {
          setIsConsent({
            visible: false,
            type: isConsent.type,
            values: isConsent.formValues,
          });
        }}
      />
    );
  };

  return (
    <Layout>
      <PageHeader title="Upload" user={user} />
      <Container>
        <UploadForm
          onSubmitForm={(values, purposeId) => {
            onSubmit(values, purposeId);
          }}
        />
      </Container>
      <ModalProgress visible={isUploading} footer={null} />
      {renderConsentChannel()}
    </Layout>
  );
}

export default UploadMedia;
