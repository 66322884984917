import React from "react";
import PropTypes from "prop-types";
import { PageHeaderWrapper, PageTitle } from "./_pageHeaderStyle";
import WelcomeUser from "./../WelcomeUser";

function PageHeader(props) {
  const { title } = props;
  return (
    <PageHeaderWrapper>
      <PageTitle>{title}</PageTitle>
      <WelcomeUser {...props} />
    </PageHeaderWrapper>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  title: "Header Title",
};

export default PageHeader;
