import React from "react";
import PropTypes from "prop-types";
import addIcon from "./assets/add.svg";
import { Container, Icon, AddLabel } from "./_uploadButtonStyle";

function UploadButton(props) {
  const { label } = props;
  return (
    <Container>
      <Icon src={addIcon} />
      <AddLabel>{label}</AddLabel>
    </Container>
  );
}

UploadButton.propTypes = {
  label: PropTypes.string,
};

UploadButton.defaultProps = {
  label: "add content",
};

export default UploadButton;
