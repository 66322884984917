import styled from "styled-components";
import { FontBody, FontHdrLarge } from "../../../utils/font-styles";

export const Container = styled.div`
  margin: 0 32px;
  margin-bottom: -20px;
  hr.zig,
  hr.zag {
    border: none;
    height: 30px;
    margin: 0 10px;
  }
  hr.zig {
    background: linear-gradient(-135deg, #fff 20px, rgba(0, 0, 0, 0) 0) 0 5px,
      linear-gradient(135deg, #fff 20px, rgba(0, 0, 0, 0) 0) 0 5px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 20px 40px;
    z-index: 100;
    position: relative;
  }

  hr.zag {
    background: linear-gradient(-135deg, #333 20px, rgba(0, 0, 0, 0) 0) 0 5px,
      linear-gradient(135deg, #333 20px, #fff 0) 0 5px;
    background-color: rgba(0, 0, 0, 0);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 20px 40px;
    z-index: 50;
    margin-top: -28px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const View = styled.div`
  display: flex;
`;

export const YourLabel = styled(FontHdrLarge)`
  font-size: 28px;
`;

export const Label = styled(FontBody)`
  margin-left: 4px;
  font-weight: 300;
`;
