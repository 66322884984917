import React from "react";
import PropTypes from "prop-types";
import { Container, Image, H1 } from "./_logoStyle";
import shareIcon from "./assets/share.svg";
import shareIconWhite from "./assets/share-2.svg";

function Logo(props) {
  const { isWhite, containerStyle } = props;
  return (
    <Container style={containerStyle}>
      {isWhite ? (
        <>
          <Image src={shareIconWhite} />
          <H1 isWhite>iShare</H1>
        </>
      ) : (
        <>
          <Image src={shareIcon} />
          <H1>iShare</H1>
        </>
      )}
    </Container>
  );
}

Logo.propTypes = {
  isWhite: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Logo.defaultProps = {
  isWhite: false,
  containerStyle: null,
};

export default Logo;
