import styled from "styled-components";

export const Container = styled.div`
  width: 250px;
  height: 44px;
  padding: 10px 0 10px 18px;
  border-radius: 100px;
  background-color: #f7f8fa;
`;

export const Input = styled.input`
  border: none;
  background: #f7f8fa;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  &::placeholder {
    color: #bbc5d5;
  }
  :focus {
    outline: none;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

export const Span = styled.span`
  margin: 0 10px;
  color: #bbc5d5;
`;
