import styled from "styled-components";
import { FontHdrMedium } from "../../../utils/font-styles";

export const Container = styled.div`
  display: flex;
  background-color: white;
  padding: 26px;
`;

export const UserText = styled(FontHdrMedium)`
  margin: 5px 0;
`;

export const Left = styled.div`
  padding-right: 102px;
`;

export const InfoWrapper = styled.div`
  padding-top: 5.5px;
`;

export const Image = styled.img`
  width: 90px;
  height: 100px;

  object-fit: contain;
`;
