import styled from "styled-components";
import { FontBodyMedium } from "../../../utils/font-styles";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 32px;
`;

export const MessageView = styled.div`
  padding-left: 20px;
`;

export const TitleText = styled(FontBodyMedium)`
  padding-bottom: 7.6px;
`;

export const IconType = styled.div`
  width: 48px;
  height: 48px;
  background: ${(props) =>
    props.type === "new" ? "rgba(255,161,1,0.3)" : "rgba(234,81,98,0.3)"};

  border-radius: 15px;
  padding: 12px 13px;
`;

export const Icon = styled.img`
  width: 22px;
  height: 22px;
`;
