import styled from "styled-components";
import { theme } from "../../../utils/constants";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: center;
`;

export const Image = styled.img`
  height: 65px;
`;

export const H1 = styled.h1`
  font-family: Raleway;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  color: ${(props) => (props.isWhite ? "white" : theme.color.p1)};
`;
