import styled from "styled-components";
import { FontHdrLarge } from "../../utils/font-styles";
import { Tabs } from "antd";

export const Container = styled.div`
  padding: 20px;
`;

export const LargeTitle = styled(FontHdrLarge)`
  text-align: left;
`;
