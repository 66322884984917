import React from "react";
import PropTypes from "prop-types";
import { Container, Wrapper } from "./_imageButtonStyle";
import { DiffFilled, MinusCircleFilled } from "@ant-design/icons";
import { theme } from "../../../utils/constants";

function ImageButton(props) {
  const { children, type } = props;

  const renderIcon = (iconType) => {
    if (iconType === "cancel") {
      return (
        <MinusCircleFilled
          style={{ color: theme.color.p1, fontSize: "20px" }}
        />
      );
    } else {
      return <DiffFilled style={{ color: theme.color.p1, fontSize: "20px" }} />;
    }
  };

  return (
    <Container {...props}>
      {renderIcon(type)} <Wrapper> {children} </Wrapper>
    </Container>
  );
}

ImageButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  type: PropTypes.oneOf(["add, cancel"]),
  width: PropTypes.number,
  disabled: PropTypes.bool,
};

ImageButton.defaultProps = {
  children: "Button",
  type: "add",
  width: null,
  disabled: false,
};

export default ImageButton;
