import styled from 'styled-components';
import { Row } from 'antd';

const StyledRow = styled(Row)`
    && {
        ${({ height }) => height && `
            height: ${height};
        `}
        ${({ bgcolor }) => bgcolor && `
            background-color: ${bgcolor};
        `}
        ${({ padding }) => padding && `
            padding: ${padding};
        `}
        ${({ margin }) => margin && `
            margin: ${margin};
        `}
    }
`;

export default StyledRow;
