import { Layout } from 'antd';
import styled from 'styled-components';

const StyledLayout = styled(Layout)`
  && {
    background-color: ${({ theme }) => theme.color.w};
    height: 100%;
    min-height: 100vh;

    ${({ bg }) => bg && `
      background-image: url('${bg}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: -200px;
    `}
    ${({ height }) => height && `
      height: ${height}px;
    `}
  }
`;

export default StyledLayout;