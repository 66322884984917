import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  ScoreLabel,
  BadgeStyled,
  ReviseLabel,
  DateLabel,
  WrapperScore,
  WrapperRevise,
} from "./_ScoreStyle";
import moment from "moment";
const dateFormat = "MMMM, DD YYYY HH:mm:ss";

const Score = (props) => {
  const { point, status, isRevised, createdAt } = props;

  const getColorStatus = (point) => {
    if (point < 60) {
      return "#ff0000";
    } else if (point > 60 && point < 80) {
      return "orange";
    } else if (point > 80) {
      return "#52c41a";
    }
  };

  return (
    <Container {...props}>
      {/* <WrapperScore>
        <ScoreLabel>AI Score</ScoreLabel>
        <BadgeStyled
          count={point}
          style={{ backgroundColor: getColorStatus(point) }}
        />
      </WrapperScore> */}

      <>Details</>

      <WrapperRevise />

      {isRevised && (
        <>
          <ReviseLabel>Revise Date</ReviseLabel>
          <DateLabel>{moment(createdAt).format(dateFormat)}</DateLabel>
        </>
      )}
    </Container>
  );
};

Score.propTypes = {
  point: PropTypes.number,
  status: PropTypes.oneOf([0, 1, -1]),
};

Score.defaultProps = {
  point: 0,
};

export default Score;
