import React from "react";
import {
  Container,
  ByRow,
  ProfileRow,
  Image,
  NameText,
  TitleText,
  TextContainer,
  MediaRow,
  MediaContainer
} from "./_createdByStyle";
import { Col, Row } from "antd";
import userIcon from "./../../UI/WelcomeUser/assets/user.svg";
import { ContentPreview } from "..";
import { CheckMediaType, CommentDate, DownloadProtectedMedia, fileSave, GetToken } from "../../../utils/helper";
import Button from "../Button";


function CreatedBy({ comment, icon }) {

  const downloadMedia = async (content) => {

    const contentType = CheckMediaType(content?.type);
    const token = GetToken();
    if(contentType === "Image"){
      const response = await DownloadProtectedMedia(token, content?.urlFull);
      window.open(response, "_blank")
    }else{
      const response = await fileSave(
        token,
        content?.urlFull,
        null,
        `${content?.name}`
      );
    }
  };

  return (
    <Container>
      <ByRow>
        <TitleText>Created by</TitleText>
        <ProfileRow>
          {icon ? <Image src={icon} /> : <Image src={userIcon} />}
          <NameText>{comment?.name}</NameText>
        </ProfileRow>

      </ByRow>
      <ByRow>
        <TitleText>Comment Date:</TitleText>
        <NameText>{CommentDate(comment?.createdAt)}</NameText>
        </ByRow>
      <ByRow>
      {
        comment?.files?.map((file)=>(
         
          <Col span={6} style={{ paddingRight: 8 }}>
          <MediaRow>
            <MediaContainer>
              <ContentPreview
                type={file?.type}
                url={file?.urlThumbnail}
              />
            </MediaContainer>
            <Button onClick={()=>downloadMedia(file)}>Download</Button>
          </MediaRow>
          </Col>
        
        ))
      }
       </ByRow>
      <TextContainer dangerouslySetInnerHTML={{ __html: comment?.comment }} />
    </Container>
  );
}

export default CreatedBy;
