import React from "react";
import PropTypes from "prop-types";
import searchIcon from "./assets/search.svg";
import { Container, Input, Icon, Span } from "./_searchInputStyle";
function SearchInput(props) {
  const { name, value, restProp } = props;

  return (
    <Container>
      <Icon src={searchIcon} />
      <Span>|</Span>
      <Input
        type="text"
        name={name}
        placeholder="Search keyword"
        value={value}
        {...restProp}
      />
    </Container>
  );
}

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

SearchInput.defaultProps = {
  name: "",
  value: "",
};

export default SearchInput;
