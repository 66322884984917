import styled from "styled-components";
import { FontBody, FontBodyMedium } from "./../../../utils/font-styles";

export const Container = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  margin-right: 24px;
`;

export const ViewScore = styled.div`
  margin-right: 24px;
  text-align: center;
`;

export const ViewAi = styled.div`
  border: solid 1px #000000;
  padding: 8px;
`;

export const TextStatus = styled(FontBodyMedium)`
  text-align: center;
  color: #d31145;
  padding-top: 3px;
  text-transform: uppercase;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ViewBottom = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const TextStyled = styled(FontBody)`
  margin-bottom: 3px;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;
