import { Card } from "antd";
import styled from "styled-components";
import { FontBodyMedium } from "../../utils/font-styles";

export const Container = styled.div`
  padding: 10px 26px 30px 26px;
`;

export const StatsContent = styled.div`
  margin-top: 20px;
`;

export const StatsCard = styled(Card)`
  margin: 0 10px 0 0;
`;

export const StatsItem = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
`;

export const StatsLabel = styled(FontBodyMedium)`
  margin-bottom: 15px;
`;
