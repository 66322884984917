import styled from "styled-components";
import { FontBodyStrong } from "../../../utils/font-styles";

export const Container = styled.div`
  align-items: "center";
  justify-content: center;
`;

export const Icon = styled.img`
  width: 27.3px;
  height: 29px;
`;

export const AddLabel = styled(FontBodyStrong)`
  margin-top: 8px;
  color: #d31145;
`;
