import { Button, Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { fileSave, GetToken } from "../../../utils/helper";
import ContentPreview from "../ContentPreview";
import Score from "../Score";
import { MediaContainer, DetailLabel } from "./_AiScoreDetailModalStyle";

const AiScoreDetailModel = ({ content, details, visible, onDismiss }) => {
  const downloadFile = async () => {
    const token = GetToken();
    const response = await fileSave(
      token,
      content?.urlFull,
      null,
      `${content?.name}`
    );
  };

  return (
    <Modal
      title="Details"
      centered
      visible={visible}
      onCancel={onDismiss}
      footer={[
        <Button
          key="back"
          type="primary"
          onClick={() => {
            downloadFile();
          }}
        >
          DOWNLOAD FILE
        </Button>,
        <Button
          key="back"
          onClick={() => {
            onDismiss();
          }}
        >
          CLOSE
        </Button>,
      ]}
    >
      <MediaContainer>
        <ContentPreview
          type={content?.type}
          url={content?.urlThumbnail}
          widthPreview={200}
          heightPreview={260}
          widthVideo={250}
        />
      </MediaContainer>
      <Score
        point={content?.ai_score}
        status={null}
        isRevised={content?.isRevised}
        createdAt={content?.createdAt}
      />
      {/* <DetailLabel>{content?.ai_detail}</DetailLabel> */}
    </Modal>
  );
};

AiScoreDetailModel.propTypes = {
  visible: PropTypes.bool,
  onDismiss: PropTypes.func,
};

AiScoreDetailModel.defaultProps = {
  visible: false,
};

export default AiScoreDetailModel;
