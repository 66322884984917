import styled from "styled-components";
import { theme } from "../../../utils/constants";
import { FontBody } from "../../../utils/font-styles";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  padding-left: 8px;
  color: ${theme.color.p1};
`;
