import { Layout } from 'antd';
import styled from 'styled-components';

const StyledContent = styled(Layout.Content)`
  && {
    ${({ headerheight }) => headerheight && `
      padding-top: ${headerheight};
    `}
  }
`;

export default StyledContent;
