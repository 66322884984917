import styled from "styled-components";
import { theme } from "../../../utils/constants";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CommentOutlined,
} from "@ant-design/icons";

export const Container = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: 8px;
  width: 100%;
  background: #fff;
  border-radius: 2px;
  border: solid 0.5px #c1c1c1;
  height: 40px;
  color: #000;
  cursor: pointer;
  line-height: 22px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.9;
    background: #e8e8e8;
  }
  &:disabled {
    transform: scale(1);
    opacity: 0.3;
    filter: alpha(opacity=40);
  }
`;

export const ApproveIcon = styled(CheckCircleFilled)`
  font-size: 14px;
  color: #00ff00;
  padding-right: 10px;
`;

export const RejectIcon = styled(CloseCircleFilled)`
  font-size: 14px;
  color: ${theme.color.p1};
  padding-right: 10px;
`;

export const CommentIcon = styled(CommentOutlined)`
  font-size: 12px;
  color: #ffd700;
  padding-right: 10px;
`;
