import styled from "styled-components";
import { theme } from "../../../utils/constants";

export const StyledButton = styled.button`
  background: ${(props) =>
    props.secondary
      ? theme.color.c0
      : props.ghost
      ? "#eaeaea"
      : theme.color.p1};
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  height: 40px;
  color: ${(props) => (props.ghost ? "#000" : "#fff")};
  cursor: pointer;
  border: ${(props) => (props.ghost ? "solid 0.8px #000" : "none")};
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 500;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    transform: scale(1);
    opacity: 0.3;
    filter: alpha(opacity=40);
  }
  border-radius: 2px;
`;
