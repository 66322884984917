import React from "react";
import ContentInstanceList from "../ContentInstanceList";
import { YourUploads } from "../UI";
import { Container } from "./_contentInstanceStyle";

const ContentInstance = ({ data, showHeader }) => {
  return (
    <>
      <Container>
        {showHeader && <YourUploads total={data.length} />}
        <ContentInstanceList data={data} />
      </Container>
    </>
  );
};

export default ContentInstance;
