import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { useIdleTimer } from "react-idle-timer";

let countdownInterval;
let timeout;

const { confirm } = Modal;

export const SessionTimeout = ({ isAuthenticated, logOut }) => {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = async (isTimedOut = false) => {
    try {
      setTimeoutModalOpen(false);
      clearSessionInterval();
      clearSessionTimeout();
      logOut();
    } catch (err) {
      console.error(err);
    }
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    const delay = 1000 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };

  const showConfirmTimeout = () => {
    confirm({
      title: "Your session is expired, please log in again.",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "OK",
      cancelButtonProps: { style: { display: "none" } },
      onOk() {
        handleLogout(false);
      },
    });
  };

  useEffect(() => {
    if (timeoutModalOpen) {
      showConfirmTimeout();
    }
  }, [timeoutModalOpen]);

  const idleTimer = useIdleTimer({ timeout: 900000, onIdle, onActive });

  return null;
};
