import styled from "styled-components";
import { FontBody } from "../../../utils/font-styles";

export const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TitleText = styled(FontBody)`
  padding-right: 40px;
`;

export const ByRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
`;


export const MediaContainer = styled.div`
  height: 130px;
  background-color: "#000";
`;

export const MediaRow = styled.div`
  display: block;
  text-align: center;
  box-shadow: 0 1.5px 13px 0 rgba(0, 0, 0, 0.09);
  padding: 8px;
`;

export const ProfileRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

export const NameText = styled.div`
  text-align: center;
  padding-left: 10px;
`;

export const AttachRow = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

export const Files = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -6px;
`;

export const FileRow = styled.a``;

export const TextContainer = styled.div`
  border-top: solid 0.5px #c1c1c1;
  padding: 10px 0 20px 0;
`;
