import React from "react";
import { HumanizeDate } from "../../../utils/helper";
import ContentPreview from "../ContentPreview";
import {
  CardWrapper,
  Container,
  TitleLabel,
  PostLabel,
} from "./_InstanceCardStyle";

const InstanceCard = ({ item, onClick }) => {
  const previewData = item?.Contents?.length > 0 ? item?.Contents[0] : null;
  return (
    <CardWrapper>
      <Container onClick={() => onClick()}>
        <ContentPreview
          type={previewData?.type}
          url={previewData?.urlThumbnail}
        />
        <TitleLabel>{item?.title}</TitleLabel>
        <PostLabel>Posted {HumanizeDate(item?.createdAt)} ago</PostLabel>
      </Container>
    </CardWrapper>
  );
};

export default InstanceCard;
