import React from "react";
import { SubTitle, Item, Wrapper } from "./_modalConsentStyle";

export const renderAdvertisingContentType = (channel="AGY") => {
  return (
    <Wrapper>
      <SubTitle>GENERAL NOTES</SubTitle>
      <Item>
        <ol>
          <li>
            Marketing materials/ collaterals cannot be ‘blind’, except
            recruitment advertisements with prior approval from Business
            Development.&nbsp;{(channel==="AGY"?"For clarity, blind marketing materials includes materials with no authorised rep liner or any mention of product features/ benefits/ values with no product name indicated.":"")}
          </li>
          {(channel==="AGY"?<li>Prior approval from the Company must be obtained before using any self- created marketing materials or amended (other than areas that allow for customisation) pre- approved marketing templates by the company. </li>:"")}
          <li>
            Indicate the authorised representative liner: (Name of FC)  {(channel=="AGY"?"SP-XXX is an authorised representative of AIA Singapore Private Limited (Reg. No. 201106386R)":`AIA FA
            Financial (Consultant/Director/and so on) (Group/Organisation Name)
            Authorised representative of AIA Financial Advisers Private Limited
            (Reg. No. 201715016G)`)} 
          </li>
          <li>All agents to be referred as “Financial {(channel=="AGY"?"Services":"")} Consultant”</li>
          <li>
            Should you need to include your email address, please only use the
            AIA branded email (e.g.nicoletan@ {(channel=="AGY"?"aia.com.sg":"aiafa.com.sg")}).
          </li>
          <li>
            Always ensure that contents/information are factual, non-misleading
            and relevant to your customers’ needs.
          </li>
          <li>
            Any data collection is required to be appropriate and reasonable for
            the purpose. (Eg) data collection on NRIC (is regulated)/ religion/
            race is deemed not justifiable for marketing/ recruitment purposes.
          </li>
          <li>
            The contents (eg images, logos, slogans etc) do not infringe any
            copyright, trademark, patent, trade secret, right of publicity or
            privacy, or other proprietary rights.
          </li>
          <li>
            Prior approval from the Company must be obtained before conducting
            any prospecting activity (eg street canvassing, door knocking,
            roadshow, worksite marketing etc)
          </li>
          <li>
            Gentle reminder that under PDPA, to ensure that there is valid
            marketing consent before marketing to your prospects via their
            preferred communication modes and the validity period is 21 days
            before the distribution list is required to be scrubbed again.
          </li>
          {(channel==="AGY"?<li>Any personal data collection is required to be hosted on MS Form and the relevant PDPA disclosures for marketing/ recruitment purposes to be indicated</li>:"")}
          
        </ol>
      </Item>
    </Wrapper>
  );
};

export const renderContactSurveyRegForm = () => {
  return (
    <Wrapper>
      <SubTitle>
        AIA PRESENTATION TEMPLATE/ APPROVED DATA COLLECTION PLATFORM:
      </SubTitle>
      <Item>
        <ol>
          <li>
            The “General &amp; Product related” powerpoint file is the AIA FA
            presentation slide template which you may use when doing up
            presentations on AIA products or general financial planning
            education for your customers.
          </li>
          <li>
            You are encouraged to use this template which can be downloaded from
            Agent Internet Access. If you intend to use other templates, please
            note to include the proper representation (i.e. Authorised
            Representative Liner).
          </li>
          <li>
            Only approved and official data collection platform is Microsoft
            Forms. Please refer to the memo, AIA FA Memo 20200102 - Branded
            Email Migration Completion and New Password Requirements1, posted in
            Agent Internet Access for further details.
          </li>
        </ol>
      </Item>
    </Wrapper>
  );
};

export const renderAllRecruitmentMaterial = () => {
  return (
    <Wrapper>
      <SubTitle>RECRUITMENT MATERIALS:</SubTitle>
      <Item>
        <ol>
          <li>
            Prior approval from Recruitment Officer must be sought for ‘blind’
            recruitment advertisements.
          </li>
          <li>
            Always ensure that contents/information are factual, non-misleading
            and relevant.
          </li>
          <li>
            Any data collection is required to be appropriate and reasonable for
            the purpose. (E.g.) data collection on NRIC (is regulated)/ religion/
            race is deemed not justifiable for marketing/ recruitment purposes.
          </li>
          <li>
            The contents (e.g., images, logos, slogans etc) do not infringe any
            copyright, trademark, patent, trade secret, right of publicity or
            privacy, or other proprietary rights.
          </li>
          <li>
            Personal data collection from candidates for recruitment purposes.
            Please note that under PDPA, you must disclose the purpose and use
            of the data collection: -- By providing your personal data to us,
            you consent to your personal data being used, disclosed, stored,
            retained and processed by AIA Financial Advisers Private Limited
            (Reg. No. 201715016G), its associated persons/organisations, its and
            their third party service providers and its or their representatives
            ("AIA Persons"), whether within or outside Singapore, for the
            purposes of recruitment and related general administration. Thank
            you. OR I consent to my Personal Data being used, disclosed, stored,
            retained and processed by AIA Persons for the purposes of
            recruitment and related general administration.
          </li>
          <li>
            Guidelines for Advertisements (Blind Ads)
            <ol type="i">
              <li>
                No information relating to AIA Financial Advisers ("AIA FA"),
                including but not limited to, AIA products and services, Company
                information, Group/Organisation address and “AIA FA Financial
                Consultant” title.
              </li>
              <li>
                Listing of minimum requirements for advertised job:
                <ol>
                  <li>
                    Minimum full certificate in GCE 'A' Level, International
                    Baccalaureate Diploma qualification, diploma awarded by a
                    polytechnic in Singapore or equivalent.
                  </li>
                  <li>At least 21 years old</li>
                </ol>
              </li>
              <li>
                Proper handling of interested candidates
                <ol>
                  <li>
                    Pre-interview: When candidate is contacted for an interview,
                    Group/Organisation must not mislead or provide false
                    information. Group/Organisation should declare and identify
                    that they are representing AIA FA and the position offered,
                    i.e. AIA FA Financial Consultant position
                  </li>
                  <li>
                    During Interview: Group/Organisation is to inform candidates
                    of the company name at the beginning of the interview, i.e.
                    AIA FA and the key job nature of AIA FA Financial
                    Consultant.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Guidelines for Advertisements (AIA FA-Branded)
            <ol type="i">
              <li>
                Indicate the authorised representative liner:
                (Group/Organisation Name) Authorised representative of AIA
                Financial Advisers Private Limited (Reg. No. 201715016G)
              </li>
              <li>
                Title of the position must be indicated clearly as AIA FA
                Financial Consultants
              </li>
              <li>
                Minimum full certificate in GCE 'A' Level, International
                Baccalaureate Diploma qualification, diploma awarded by a
                polytechnic in Singapore or equivalent.*
              </li>
              <li>
                Minimum age requirement of the position is at least 21 years
              </li>
              <li>
                There must be no mention of nationality, gender or race in your
                advertisements
              </li>
              <li>
                Advertisements must not specify any financing schemes (eg.) AIA
                Entrepreneur Power Scheme (EPS) / AIA Foreign Talent Scheme
                (FORTS)
              </li>
              <li>
                Should you need to include your email address, please only use
                the AIA FA branded email (e.g. nicoletan@aiafa.com.sg)
              </li>
              <li>
                A standard template for recruitment advertisement has been
                developed and you are strongly encouraged to use. Please refer
                to the template posted in Agent Internet Access for your
                use.*Further specific requirements may apply to determine
                equivalent academic qualifications
              </li>
            </ol>
          </li>
        </ol>
      </Item>
    </Wrapper>
  );
};

export const renderSocialMediaAccountSetup = () => {
  return (
    <Wrapper>
      <SubTitle>
        AGENCY DISTRICT FACEBOOK / DISTRICT INSTAGRAM / DISTRICT LINKEDIN:
      </SubTitle>
      <Item>
        <ol>
          <li>
            Approval of posting content
            <ol type="i">
              <li>
                If you are sharing AIA FA content on your social media feeds –
                you do not need to seek approval but always be factual, accurate
                and not misleading when you share
              </li>
              <li>
                If you are creating new content to be shared on your social
                media such as customer videos and flyers, please note that
                content must be approved by AIA FA before use. Please refer to
                Guidelines for Advertising, Marketing and Promotional Materials
                for more information.
              </li>
            </ol>
          </li>
          <li>
            Group/Organisation Facebook pages&nbsp;-&nbsp;For Group/Organisation Facebook
            pages, you are required to include the authorised rep liner under
            the More Info &gt; About section at the end of the Group/Organisation's
            description paragraph: Group/Organisation is an authorised
            representative of AIA Financial Advisers Private Limited (Reg. No.
            201715016G). This is not an official AIA Financial Advisers Facebook
            page.
            <ol type="i">
              <li>
                Under the Services section, Group/Organisation Facebook pages
                should list themselves as providing “Financial Services” instead
                of an “Insurance Company”.
              </li>
              <li>
                Any marketing content (post visuals and content copy) on
                Group/Organisation Facebook pages are required to: <br /> ● be
                submitted for review <br /> ● include the authorised rep liner,
                Group/Organisation is an authorised representative of AIA
                Financial Advisers Private Limited (Reg. No. 201715016G), in
                images and posting copy
              </li>
            </ol>
          </li>
          <li>
            Group/Organisation Instagram pages For Group/Organisation Instagram
            pages, you are required to include the authorised rep liner:
            Group/Organisation is an authorised representative of AIA Financial
            Advisers Private Limited (Reg. No. 201715016G)
          </li>
          <li>
            Agency District LinkedIn pages For Group/Organisation and FC
            LinkedIn pages, you are required to include the authorised rep liner
            under the About Us section: Group/Organisation is an authorised
            representative of AIA Financial Advisers Private Limited (Reg. No.
            201715016G)
          </li>
        </ol>
      </Item>
    </Wrapper>
  );
};

export const renderWebsiteCheklist = (channel) => {
  return (
    <Wrapper>
      <SubTitle>AGENCY WEBSITES:</SubTitle>
      <Item>
        <ol>
          <li>
            Websites must be submitted to {(channel=="AGY"?"AIA Singapore":"AIA FA")} for review and approval BEFORE
            its launch. Please note that regular maintenance and updating is
            required to ensure that it remains accurate, current and not
            misleading.
          </li>
          <li>
            Website materials should be submitted, both online (trial site
            address) and preferably in word document format.
          </li>
          <li>
            The URL of the agency website should:
            <ol type="i">
              <li>be approved by {(channel=="AGY"?"AIA Singapore":"AIA FA")} before registration; </li>
              <li>
                NOT contain “AIA”, "{(channel=="AGY"?"AIA Singapore":"AIA FA")}" and affiliates in full or
                abbreviations. {(channel=="AGY"?"AIA Singapore":"AIA FA")} reserves the right to <u>take action</u> against {(channel=="AGY"?"District/Agency":"Groups/Organisations")} or Leaders or {(channel=="AGY"?"FSCs":"FCs")} who are found to violate&nbsp;{(channel=="AGY"?"AIA Singapore":"AIA FA")}’s website requirements. Groups/Organisations or Leaders
                or {(channel=="AGY"?"FSCs":"FCs")} will have no right to claim {(channel=="AGY"?"AIA Singapore":"AIA FA")} for whatever loss
                incurred by such actions.
              </li>
            </ol>
          </li>
          <li>
            Key elements for your website:
            <ol type="i">
              <li>
                Your {(channel=="AGY"?"District/Agency":"Groups/Organisations")} logo (if you have one, with approval by
                Tied Distribution Sales and Business Quality)
              </li>
              <li>
                Group/Organisation name and relationship with {(channel=="AGY"?"AIA Singapore":"AIA FA")} The
                &nbsp;{(channel=="AGY"?"District/Agency":"Groups/Organisations")} name in full must be included in the website
                and placed on top of every web page. You must also clearly
                define your relationship with {(channel=="AGY"?"AIA Singapore":"AIA FA")} by including the statement
                &nbsp;“{(channel=="AGY"?"SP-XXX is an authorised representative of AIA Singapore Private Limited (Reg. No. 201106386R)":"Authorised representative of AIA Financial Advisers Private Limited (Reg. No. 201715016G)")}”.
              </li>
              <li>
                Legal disclaimers on the bottom of every page
                <ol>
                  <li>
                    Must be included at the bottom of every web page and the
                    font should be at least 10-point Times New Roman, or any
                    other standard font type that is visually equivalent to that
                    font size.
                  </li>
                  <li>
                    Disclaimers may be revised from time to time and you should
                    take note to update the legal disclaimers accordingly.
                  </li>
                  <li>
                    Wordings: “This is not the official website of {(channel=="AGY"?"AIA Singapore Private Limited (Reg. No. 201106386R)":"AIA Financial Advisers Private Limited (Reg. No. 201715016G)")} ({(channel=="AGY"?"AIA Singapore":"AIA FA")}).
                    &nbsp;{(channel=="AGY"?"AIA Singapore":"AIA FA")} disclaims all warranties of merchantability and fitness
                    for purpose of the materials on this website. {(channel=="AGY"?"AIA Singapore":"AIA FA")} makes
                    no warranties or representations as to the results of the
                    use of the materials on this website in terms of their
                    correctness, accuracy and reliability. Nothing on this
                    website should be regarded as an offer or solicitation to
                    sell insurance products in any country to any person to whom
                    it is unlawful to make such an invitation or solicitation in
                    such a country.” AND “Terms of Use. (1) All trademarks,
                    service marks, trade names, product names, icons and logos
                    of {(channel=="AGY"?"AIA Singapore":"AIA FA")} are owned by AIA and {(channel=="AGY"?"AIA Singapore":"AIA FA")} and may not be used
                    without prior written permission from {(channel=="AGY"?"AIA Singapore":"AIA FA")}. (2) All AIA
                    products and services referred to in this website (or linked
                    thereto to the website) known as www. [INSERT NAME OF
                    LICENSEE WEBSITE] (the “{(channel=="AGY"?"District/Agency":"Groups/Organisations")} Website”) are
                    offered only in countries where such products and services
                    may lawfully be offered by AIA [INSERT COUNTRY] and {(channel=="AGY"?"AIA Singapore":"AIA FA")} 
                    and the materials on the said {(channel=="AGY"?"District/Agency":"Groups/Organisations")} Website are
                    not intended for use by persons located in or resident in,
                    countries that restrict the distribution of such materials.
                    (3) Neither the {(channel=="AGY"?"District/Agency":"Groups/Organisations")} Website nor the link to
                    the products or services on {(channel=="AGY"?"AIA Singapore":"AIA FA")}’s corporate website
                    should be regarded as an offer to sell or a solicitation to
                    buy any insurance product or other products or services of 
                    &nbsp;{(channel=="AGY"?"AIA Singapore":"AIA FA")} in any country to any person to whom it is unlawful
                    to make such invitation or solicitation in such country. (4)
                    No warranties regarding non-infringement of third party
                    rights, security, accuracy, fitness for purpose,
                    merchantability or freedom from computer viruses in the
                    &nbsp;{(channel=="AGY"?"District/Agency":"Groups/Organisations")} Website or the {(channel=="AGY"?"AIA Singapore":"AIA FA")} corporate website
                    (linked thereto) are given by {(channel=="AGY"?"AIA Singapore":"AIA FA")}.”
                  </li>
                </ol>
              </li>
              <li>
                Once the website is approved by {(channel=="AGY"?"AIA Singapore":"AIA FA")}, please include this at
                the bottom of the website and each web page before the
                disclaimer: "Information is correct as at DD MM YYYY".
              </li>
              <li>
              {(channel=="AGY"?"District/Agency":"Groups/Organisations")} background You may include background on how
                the {(channel=="AGY"?"District/Agency":"Groups/Organisations")} started, including achievements and how
                it has grown over the years. For the accuracy of the information
                such as {(channel=="AGY"?"AIA Singapore":"AIA FA")} achievements please cross-check with the AIA Tied
                Distribution Operations.
              </li>
              {(channel==="AGY"?<><li>Other information which you may like to list such as email address; must be an AIA branded email address or services you provide; must be as an authorised representative of AIA. </li><li>Any personal data collection is required to be hosted on MS Form and the relevant PDPA disclosures for marketing/ recruitment purposes to be indicated.</li></>:"")}
            </ol>
          </li>
        </ol>
      </Item>
    </Wrapper>
  );
};
