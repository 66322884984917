import React from "react";
import PropTypes from "prop-types";
import { ShareAltOutlined, MailOutlined } from "@ant-design/icons";
import { ICON_TYPE, theme } from "../../../utils/constants";
import { Container } from "./_actionIconStyle";

function ActionIcon(props) {
  const { onClick, type } = props;
  let Icon = (
    <ShareAltOutlined
      onClick={onClick}
      style={{ fontSize: 24, color: theme.color.p1 }}
    />
  );
  if (type === ICON_TYPE.MAIL) {
    Icon = (
      <MailOutlined
        onClick={onClick}
        style={{ fontSize: 24, color: theme.color.p1 }}
      />
    );
  }
  return <Container>{Icon}</Container>;
}

ActionIcon.propTypes = {
  type: PropTypes.oneOf([ICON_TYPE.SHARE, ICON_TYPE.MAIL]),
  onClick: PropTypes.func,
};

ActionIcon.defaultProps = {
  type: ICON_TYPE.SHARE,
  onClick: () => {},
};

export default ActionIcon;
