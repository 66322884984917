import React from "react";
import PropTypes from "prop-types";
import userIcon from "./assets/user.svg";
import { Container, Left, InfoWrapper, Image, UserText } from "./_welcomeUser";
import { FontHdrThin, FontBody } from "../../../utils/font-styles";

function WelcomeUser(props) {
  const { user, countNotification, icon } = props;
  return (
    <Container>
      <Left>
        <FontHdrThin>Hello,</FontHdrThin>
        <UserText>{user?.name}!</UserText>
        <InfoWrapper>
          <FontBody>{user?.email}</FontBody>
          {/* <FontBody>You have {countNotification} notificatios</FontBody> */}
        </InfoWrapper>
      </Left>
      {icon ? <Image src={icon} /> : <Image src={userIcon} />}
    </Container>
  );
}

WelcomeUser.propTypes = {
  countNotification: PropTypes.number,
  icon: PropTypes.string,
};

WelcomeUser.defaultProps = {
  countNotification: 17,
  icon: "",
};

export default WelcomeUser;
