import styled from "styled-components";
import { Link } from "gatsby";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  @media all and (min-width: 1200px) {
    margin-left: 65px;
  }
`;

export const LinkStyled = styled(Link)`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
`;
