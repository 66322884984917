import { Avatar, Comment, Modal, Button } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchComments } from "../../../redux/agent/actions";
import { FormatRequestDate } from "../../../utils/helper";
import ContentPreview from "../ContentPreview";
import { MediaContainer } from "./_CommentContentModalStyle";

const CommentContentModal = ({ content, visible, onDismiss }) => {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => {
    return {
      comments: state.agent.comments,
    };
  }, shallowEqual);

  // useEffect(() => {
  //   loadComments(content);
  // }, [content]);

  const loadComments = (content) => {
    if (content) {
      dispatch(fetchComments(content?.id));
    }
  };

  return (
    <Modal
      title="Comment"
      centered
      visible={visible}
      onCancel={onDismiss}
      footer={[
        <Button
          key="back"
          onClick={() => {
            onDismiss();
          }}
        >
          CLOSE
        </Button>,
      ]}
    >
      {content && (
        <>
          <MediaContainer>
            <ContentPreview type={content?.type} url={content?.urlThumbnail} />
          </MediaContainer>
          {comments?.map((comment) => (
            <Comment
              author={<a>{comment?.name}</a>}
              avatar={
                <Avatar
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                  alt={comment?.name}
                />
              }
              content={<p>{comment?.details}</p>}
              datetime={<span>{FormatRequestDate(comment?.createdAt)}</span>}
            />
          ))}
        </>
      )}
    </Modal>
  );
};

CommentContentModal.propTypes = {
  visible: PropTypes.bool,
  onDismiss: PropTypes.func,
};

CommentContentModal.defaultProps = {
  visible: false,
};

export default CommentContentModal;
